import { inject, Injectable } from '@angular/core';
import { BudgetObjectDetailsService } from '../types/budget-object-details-service.interface';
import { CampaignDetailsState } from '../types/budget-object-details-state.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { map, switchMap, tap, take } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { BudgetObjectCreationContext } from '../types/details-creation-context.interface';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { Metric } from '../components/details-metrics/details-metrics.type';
import { CampaignStateMapper } from './state-mappers/campaign-state-mapper.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetObjectAllocation, CampaignAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { BudgetObjectTagsService } from './budget-object-tags.service';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { TasksService } from 'app/shared/services/backend/tasks.service';
import { TaskDO } from 'app/shared/types/task.interface';
import { SaveDetailsContext } from '../types/save-details-context.interface';
import { ChurnZeroService, EventName } from 'app/shared/services/churn-zero.service';
import { ProgramDO } from 'app/shared/types/program.interface';
import { MetricDataDO } from 'app/shared/services/backend/metric.service';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { Campaign, CampaignAllocationDO, CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { DeepPartial } from 'app/shared/types/deep-partial.type';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { ExpenseDO } from '@shared/types/expense.interface';
import { PendoEventName, PendoManagerService, PendoObjectType } from '@shared/services/pendo-manager.service';
import { injectRemainingAmountsIntoAllocation, sumAmounts } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { DialogContext } from '@shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { CFCampaignDetailsContext } from '../components/custom-fields/custom-field.service';

export interface CampaignDetailsForm {
  name: string;
  segment: HierarchySelectItem;
  ownerId: number;
  typeId: number;
  customType: string;
  targetAudience: string;
  messaging: string;
  notes: string;
  startDate: Date;
  endDate: Date;
  location: string;
  vendorId?: number;
  vendorName?: string;
  currencyCode?: string;
  glCode?: string | number;
  amountStatus?: CEGStatus;
}

@Injectable()
export class CampaignDetailsService implements BudgetObjectDetailsService<CampaignDetailsState> {
  private readonly configuration = inject(Configuration);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly expenseService = inject(ExpensesService);
  private readonly tasksService = inject(TasksService);
  private readonly tagsManager = inject(BudgetObjectTagsService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly stateMapper = inject(CampaignStateMapper);
  private readonly churnZeroService = inject(ChurnZeroService);
  private readonly pendoManager = inject(PendoManagerService);

  /**
   * State properties to check updates for
   */
  private updatableStateProps = [
    'startDate',
    'endDate',
    'messaging',
    'typeId',
    'name',
    'notes',
    'createdBy',
    'budgetId',
    'ownerId',
    'targetAudience',
    'segment',
    'parentObject',
    'spreadSegmentToChildren',
    'keyMetricId',
    'lockForIntegrations',
    'vendor',
    'vendorName',
    'glCode',
    'amountStatus',
    'currencyCode',
    'amount'
  ];
  /**
   * State properties to exclude from state diff checks
   */
  public readonly statePropsToExclude = [
    'metricMappings',
    'metricData',
    'spreadSegmentToChildren',
    'statusTotals',
    'expenses',
    'attachmentMappings',
    'vendorName'
  ];

  public static isSegmentless(state: CampaignDetailsState): boolean {
    if (!state) {
      return false;
    }
    return !state.segment.segmentId && !state.segment.sharedCostRuleId;
  }

  public createCampaignDetailsState(
    campaign: Partial<CampaignDO>,
    tagMappings: TagMapping[],
    metricMappings: Metric[],
    campaigns: CampaignDO[],
    programs: ProgramDO[],
    expenses: any[],
    tasks: TaskDO[] = []
  ): CampaignDetailsState {
    this.budgetObjectDetailsManager.applyMappingReducedValues(campaign, metricMappings);
    const state = this.stateMapper.dataObjectToState(campaign);

    return {
      ...state,
      tagMappings: tagMappings || [],
      metricMappings: metricMappings || [],
      campaigns: campaigns || [],
      programs: programs || [],
      expenses: expenses || [],
      spreadSegmentToChildren: false,
      tasks
    } as CampaignDetailsState;
  }

  cloneObject(objectId: number): Observable<BudgetObjectCloneResponse> {
    return this.campaignService.cloneCampaign(objectId);
  }

  deleteObject(objectId: number): Observable<void> {
    return this.campaignService.deleteCampaign(objectId);
  }

  closeObject(objectId: number): Observable<CampaignDO> {
    return this.campaignService.updateCampaign(objectId, { mode: ObjectMode.Closed })
      .pipe(
        switchMap(() => this.campaignService.getCampaign(objectId))
      );
  }

  reopenObject(objectId: number): Observable<CampaignDO> {
    return this.campaignService.updateCampaign(objectId, { mode: ObjectMode.Open })
      .pipe(
        switchMap(() => this.campaignService.getCampaign(objectId))
      );
  }

  moveToBudget(objectId: number, budgetId: number, companyId: number): Observable<any> {
    return this.campaignService.moveToBudget(objectId, budgetId, companyId);
  }

  loadDetails(companyId: number, budgetId: number, objectId: number, data: { isCEGMode: boolean }): Observable<CampaignDetailsState> {
    const isCEGMode = data.isCEGMode;
    return forkJoin([
      this.campaignService.getCampaign(objectId).pipe(
        switchMap(campaign =>
          !isCEGMode ?
            of(campaign) :
            this.loadCampaignAllocationAmounts(budgetId, objectId, campaign.source_currency, campaign.campaign_allocations).pipe(map(() => campaign))
        )
      ),
      this.tagsManager.getTagMappings(companyId, objectId, this.configuration.OBJECT_TYPES.campaign),
      this.budgetObjectDetailsManager.getMetricMappings(companyId, objectId, this.configuration.OBJECT_TYPES.campaign),
      this.getChildCampaigns$(companyId, budgetId, objectId).pipe(
        switchMap(campaigns => {
          return this.budgetObjectDetailsManager.getMetricMappingsForObjects(
            companyId, campaigns.map(c => c.id), this.configuration.OBJECT_TYPES.campaign
          ).pipe(map(mappings => ({ campaigns, mappings })))
        })
      ),
      this.getCampaignPrograms$(companyId, budgetId, objectId),
      this.getCampaignExpenses$(companyId, budgetId, objectId, isCEGMode),
      this.tasksService.getTasks(companyId, { campaign: objectId })
    ]).pipe(map(
      ([campaign, tagMappings, metricMappings, childCampaignsData, programs, expenses, tasks]) => {
        this.addChildSourcesToParentMetrics(metricMappings, childCampaignsData.mappings);
        return this.createCampaignDetailsState(
          campaign,
          tagMappings,
          metricMappings,
          childCampaignsData.campaigns,
          programs,
          expenses,
          tasks
        );
      }
    ));
  }

  getChildCampaigns$(companyId: number, budgetId: number, campaignId: number): Observable<CampaignDO[]> {
    return this.campaignService.getCampaigns(
      { company: companyId, budget: budgetId, parent_campaign_ids: campaignId.toString(), include_totals: true }
    );
  }

  getCampaignPrograms$(companyId: number, budgetId: number, campaignId: number): Observable<ProgramDO[]> {
    return this.programService.getPrograms(
      { company: companyId, budget: budgetId, campaign_ids: campaignId.toString(), include_totals: true }
    );
  }

  getCampaignExpenses$(companyId: number, budgetId: number, campaignId: number, isCEGMode: boolean): Observable<ExpenseDO[]> {
    return this.expenseService.getExpenses(
      { company: companyId, budget: budgetId, campaign_ids: campaignId.toString(), include_nested: !isCEGMode }
    );
  }

  loadCampaignAllocationAmounts(
    budgetId: number,
    objectId: number,
    currencyCode: string,
    campaignAllocs: CampaignAllocation[]
  ): Observable<CampaignAllocation[]> {
    return this.campaignService.getCampaignAmountsByTimeframes(budgetId, objectId, currencyCode).pipe(
      map(amounts => {
        injectRemainingAmountsIntoAllocation(amounts, campaignAllocs);
        return campaignAllocs;
      })
    );
  }

  addChildSourcesToParentMetrics(parentMappings: Metric[], childMappings: Metric[]) {
    if (!childMappings?.length || !parentMappings?.length) {
      return;
    }
    const metricSourcesSets = {};
    childMappings.forEach(mappingChild => {
      if (!metricSourcesSets[mappingChild.name]) {
        metricSourcesSets[mappingChild.name] = new Set();
      }
      const sourcesSet = metricSourcesSets[mappingChild.name];
      mappingChild.sources.forEach(metric => sourcesSet.add(metric));
    })

    parentMappings.forEach(mappingParent => {
      const commonSet = metricSourcesSets[mappingParent.name] || new Set();
      mappingParent.sources.forEach(metric => commonSet.add(metric));
      mappingParent.sources = [...commonSet];
    })
  }

  reloadChildObjects(companyId: number, state: CampaignDetailsState): Observable<ExpenseDO[]> {
    const { budgetId, objectId } = state;

     return this.campaignService.getCampaigns(
      { company: companyId, budget: budgetId, parent_campaign_ids: objectId.toString(), include_totals: true }
    ).pipe(
      switchMap(campaigns => {
        state.campaigns = campaigns || [];
        return this.programService.getPrograms(
          { company: companyId, budget: budgetId, campaign_ids: objectId.toString(), include_totals: true }
        );
      }),
      switchMap(programs => {
        state.programs = programs || [];
        return this.expenseService.getExpenses(
          { company: companyId, budget: budgetId, campaign_ids: objectId.toString(), include_nested: true }
        );
      }),
      tap(expenses => {
        state.expenses = expenses || [];
        this.budgetDataService.loadLightPrograms(companyId, budgetId);
      })
    );
  }

  updateExpenses(companyId: number, state: CampaignDetailsState) {
    const { budgetId, objectId } = state;

    return forkJoin([
      this.campaignService.getCampaign(objectId),
      this.expenseService.getExpenses(
        { company: companyId, budget: budgetId, campaign_ids: objectId.toString(), include_nested: true }
      )
    ]).pipe(
      tap(([campaign, expenses]) => {
        state.expenses = expenses;
        state.statusTotals = campaign.status_totals;
      })
    );
  }

  getCampaignMetricsData(companyId: number, campaignId: number, childCampaignsIds: number[]): Observable<[MetricDataDO[], Metric[]]> {
    return forkJoin([
      this.campaignService.getCampaign(campaignId).pipe(map(campaign => campaign.metric_data)),
      this.budgetObjectDetailsManager.getMetricMappings(companyId, campaignId, this.configuration.OBJECT_TYPES.campaign).pipe(
        switchMap(parentMetrics => (
          this.budgetObjectDetailsManager.getMetricMappingsForObjects(
            companyId, childCampaignsIds, this.configuration.OBJECT_TYPES.campaign
          ).pipe(
            map(childMetrics => {
              this.addChildSourcesToParentMetrics(parentMetrics, childMetrics);
              return parentMetrics;
            })
          )
        ))
      ),
    ]);
  }

  saveDetails(
    prevObjectDetails: CampaignDetailsState,
    newObjectDetails: CampaignDetailsState,
    saveDetailsCtx?: SaveDetailsContext,
    CFDetailsCtx?: CFCampaignDetailsContext
  ): Observable<CampaignDO> {
    if (prevObjectDetails) {
      return this.updateDetails(prevObjectDetails, newObjectDetails, saveDetailsCtx, CFDetailsCtx).pipe(
        tap(() => {
          this.churnZeroService.trackEvent(EventName.CampaignUpdate);
          this.pendoManager.track(PendoEventName.ObjectUpdated, {
            type: PendoObjectType.Campaign
          });
        })
      );
    } else {
      return this.createDetails(newObjectDetails, saveDetailsCtx, CFDetailsCtx).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectCreated, {
            type: PendoObjectType.Campaign
          })
        )
      );
    }
  }

  initDetails(context: BudgetObjectCreationContext, data: Partial<CampaignDO>): Observable<CampaignDetailsState> {
    const campaign: Partial<CampaignDO> = { ...data };
    const state = this.createCampaignDetailsState(campaign, [], [], [], [], []);
    const contextParent = context?.parent;
    const { OBJECT_TYPES } = this.configuration;

    state.typeId = context && context.objectTypeId || null;
    state.glCode = context && context.glCodeId || null;
    state.vendor = context && context.vendorId || null;
    state.ownerId = context && context.ownerId || null;

    if (contextParent) {
      state.parentObject = contextParent;
      state.goalId = contextParent?.type === OBJECT_TYPES.goal ? contextParent.id : null;
      state.parentCampaignId = contextParent?.type === OBJECT_TYPES.campaign ? contextParent.id : null;
      state.messaging = context?.messaging;
      state.targetAudience = context?.targetAudience;
    }

    return of(state);
  }

  /**
   * Fill state with default values (after load/init)
   */
  preFillStateAllocations(
    state: CampaignDetailsState,
    budgetTimeframes: BudgetTimeframe[] = [],
    options: { suppressMode: boolean; CEGMode: boolean; }
  ) {
    if (!options.suppressMode) {
      state.allocations = this.budgetObjectDetailsManager.initBudgetObjectAllocations(
        state.allocations,
        budgetTimeframes,
        options.CEGMode
      );
    }
  }

  private updateDetails(
    prevState: CampaignDetailsState,
    newState: CampaignDetailsState,
    saveDetailsCtx?: SaveDetailsContext,
    CFDetailsCtx?: CFCampaignDetailsContext
  ): Observable<CampaignDO> {
    const stateDiff =
      this.budgetObjectDetailsManager.getAllocatableStateDiff(
        prevState,
        newState,
        this.updatableStateProps
      ) as Partial<CampaignDetailsState>;
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const campaignPayload = this.stateMapper.stateToDataObject(stateDiff, isCurrentBudgetWithNewCEGStructure);
    const allocationsDiff = stateDiff.allocations;
    const switchedToCommitted = stateDiff.amountStatus && stateDiff.amountStatus === CEGStatus.COMMITTED;
    const isBudgetSuppressMode = Boolean(saveDetailsCtx?.suppressMode);

    // Allocations are to be updated first to make sure SCR rules are applied correctly!
    return this.updateAllocations(newState, allocationsDiff || [], newState.companyId, newState.objectId).pipe(
      switchMap(updatedAllocs => this.campaignService.updateCampaignAndFetchResult(newState.objectId, campaignPayload, CFDetailsCtx?.customFieldsStateDiff).pipe(
        tap(updatedCampaign => {
          newState.statusTotals = { ...updatedCampaign.status_totals }
        }),
        map(updatedCampaign => {
          const hasChildren = newState.campaigns.length || newState.programs.length;
          if (isCurrentBudgetWithNewCEGStructure && switchedToCommitted && hasChildren) {
            // move all remaining_planned to remaining_committed as all children become Committed
            newState.allocations.forEach(alloc => {
              alloc.source_remaining_committed = sumAmounts(alloc.source_remaining_committed, alloc.source_remaining_planned);
              alloc.source_remaining_planned = null;
            });
            newState.allocations = [...newState.allocations];
          }
          this.budgetObjectDetailsManager.patchAllocations(newState.allocations, updatedAllocs);
          return updatedCampaign;
        })
      )),
      switchMap((updatedCampaign: CampaignDO) =>
        this.applySharedCostRuleToUpdatedCampaign(
          isBudgetSuppressMode,
          newState.objectId,
          newState.segment.sharedCostRuleId,
          prevState.segment.sharedCostRuleId
        ).pipe(
          map(() => updatedCampaign)
        )
      )
    );
  }

  private createDetails(state: CampaignDetailsState, saveDetailsCtx?: SaveDetailsContext, CFDetailsCtx?: CFCampaignDetailsContext): Observable<CampaignDO> {
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const payload: DeepPartial<CampaignDO> = this.stateMapper.stateToDataObject(state, isCurrentBudgetWithNewCEGStructure);
    const isBudgetSuppressMode = Boolean(saveDetailsCtx?.suppressMode);
    payload.campaign_allocations = this.budgetObjectDetailsManager.getCreateAllocationsPayload(state);

    return this.campaignService.createCampaign(payload, CFDetailsCtx?.customFieldsStateDiff)
      .pipe(
        switchMap(createdCampaign =>
          this.applySharedCostRuleToAddedCampaign(
            isBudgetSuppressMode,
            createdCampaign.id,
            state.segment && state.segment.sharedCostRuleId
          ).pipe(
            map(() => createdCampaign)
          )
        ),
        tap((createdCampaign: CampaignDO) => {
          this.budgetObjectDetailsManager.patchState(state, createdCampaign);
          this.budgetObjectDetailsManager.patchAllocations(state.allocations, createdCampaign.campaign_allocations);
          this.budgetObjectDetailsManager.initStatusTotals(state, createdCampaign.amount);
        })
      );
  }

  private updateAllocations(
    state: CampaignDetailsState,
    targetAllocations: BudgetObjectAllocation[],
    companyId?: number,
    objectId?: number
  ): Observable<Partial<CampaignAllocationDO>[]> {
    const getAllocationsPayload = (alloc: CampaignAllocation, create = false) => {
      const allocPayload: Partial<CampaignAllocation> = {
        source_amount: alloc.source_amount,
        mode: alloc.mode,
        campaign: alloc.campaign || objectId,
        lock_for_integrations: true,
        source_forecast_amount: alloc.source_forecast_amount,
      };

      if (create) {
        allocPayload.company = companyId;
        allocPayload.company_budget_alloc = alloc.company_budget_alloc;
      }

      return allocPayload;
    };

    return targetAllocations?.length ?
      forkJoin(
        state.allocations.map((stateAllocation) => {
          const stateAllocationExists = !!stateAllocation.id;
          const payload = getAllocationsPayload(stateAllocation, !stateAllocationExists);
          if (stateAllocationExists) {
            const targetAllocation = targetAllocations.find(alloc => alloc.id === stateAllocation.id);
            if (!targetAllocation) {
              return of(null);
            }

            return this.campaignService.updateCampaignAllocation(stateAllocation.id, payload);
          } else {
            // create new allocation if not exists
            return this.campaignService.addCampaignAllocation(payload);
          }
        })
      ) :
      of(null);
  }

  private applySharedCostRuleToAddedCampaign(
    isBudgetSuppressMode: boolean,
    campaignId: number,
    sharedCostRuleId?: number
  ): Observable<void> {
    return !isBudgetSuppressMode && sharedCostRuleId ?
      this.campaignService.split(campaignId) :
      of(null);
  }

  private applySharedCostRuleToUpdatedCampaign(
    isBudgetSuppressMode: boolean,
    campaignId,
    newRuleId?: number,
    prevRuleId: number = null
  ): Observable<void> {
    return !isBudgetSuppressMode && newRuleId != null && newRuleId === prevRuleId ?
      this.campaignService.split(campaignId) :
      of(null);
  }

  public hasChanges(prevState: CampaignDetailsState, currentState: CampaignDetailsState): boolean {
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const statePropsToExclude = [...this.statePropsToExclude];
    if (CampaignDetailsService.isSegmentless(currentState)) {
      statePropsToExclude.push('allocations');
    }
    if (!isCurrentBudgetWithNewCEGStructure) {
      statePropsToExclude.push('amountStatus');
    }
    return this.budgetObjectDetailsManager.hasChanges(prevState, currentState, statePropsToExclude);
  }

  logObjectView(objectId: number): Observable<void> {
    return this.campaignService.logCampaignView(objectId);
  }

  getCampaign(campaignId: number, campaigns: Campaign[] | LightCampaign[]): Observable<Campaign> {
    const campaign = campaigns.find(cmpn => cmpn.id === campaignId);
    if (!campaign) {
      return of(null);
    }
    if (!campaign.isShort) {
      return of(campaign as Campaign);
    }
    return this.campaignService.getCampaign(campaignId).pipe(
      map(campaignDO => this.budgetDataService.convertCampaignDO(campaignDO))
    );
  }

  getCampaignDO(campaignId: number): Observable<CampaignDO> {
    return this.campaignService.getCampaign(campaignId);
  }

  public static showManualChangeConfirmDialog(integrationName: string, dialog: MatDialog, cb?: () => void): void {
    const dialogData: DialogContext = {
      title: 'Change allocation manually',
      content: `If you manually change an allocation for this campaign, <br>
                it will no longer receive updates from ${integrationName}.`,
      cancelAction: {
        label: 'Cancel',
        handler: null
      },
      submitAction: {
        label: 'Ok',
        handler: null
      }
    };

    dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      data: dialogData
    })
    .afterClosed().pipe(
      take(1)
    ).subscribe(() => {
      cb?.();
    });
  }
}
