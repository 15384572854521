import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BudgetEditDialogData, BudgetEditDialogFormData, } from './budget-edit-modal.types';
import { BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { CUSTOM_DATE_FORMATS } from 'app/shared/constants/date-picker-format';
import { Configuration } from 'app/app.constants';
import { ForecastBudgetService } from 'app/budget-settings/services/forecast-budget.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalContext } from '../../../shared/components/modal-with-icon-header/modal-with-icon-header.component';
import { IconHeaderModalService } from '../../../shared/services/modal-with-icon-header.service';

@Component({
  selector: 'budget-edit-modal',
  templateUrl: './budget-edit-modal.component.html',
  styleUrls: ['./budget-edit-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class BudgetEditModalComponent implements OnInit {
  @ViewChild('captchaTemplate') captchaTemplate!: TemplateRef<any>;  
  captchaInput: string = '';
  modalData!: ModalContext;
  redHeader: boolean = true;
  form: UntypedFormGroup;
  editMode = false;
  timeframeTypes = [
    {
      id: BudgetTimeframesType.Month,
      title: 'Monthly',
    },
    {
      id: BudgetTimeframesType.Quarter,
      title: 'Quarterly',
    }
  ]
  MAX_TEXT_INPUT_LENGTH = this.configuration.MAX_TEXT_INPUT_LENGTH;
  currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: false, prefix: '' };
  whiteListedDomainsForForecastedBudget = ['*']; // Allow whitelisting in all the environments
  showForecastToggle = true;
  forecastEnabled = false;
  forecastTooltip = 'Enabling Forecast helps you track the budget in the forecast column. It keeps the original budget for reference.'
  private destroy$ = new Subject<void>();
  
  constructor(
    public dialogRef: MatDialogRef<BudgetEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BudgetEditDialogData,
    public fb: UntypedFormBuilder,
    private readonly configuration: Configuration,
    private forecastBudgetService: ForecastBudgetService,
    private modalService: IconHeaderModalService
  ) {
  }

  ngOnInit(): void {
    this.editMode = !!this.data.budgetData;

    this.modalData = {
      title: this.configuration.forecastDescriptions.disableTitle,
      content: this.configuration.forecastDescriptions.disableContent,
      icon: {
        prefix: 'fas',
        name: 'exclamation-triangle'
      },
      buttons: [
        { text: 'Disable', color: 'warn', disabled: true }
      ]
    };
    
    // Add yearly timeframe type if it's an edit mode and Yearly Budget was selected
    if(this.editMode && this.data.budgetData?.type === BudgetTimeframesType.Year) {
      this.timeframeTypes.push({ id: BudgetTimeframesType.Year, title: 'Yearly' });
    }

    if(this.editMode && !this.data.budgetData.cegStatusesEnabled) {
      this.showForecastToggle = false; // Don't show forecast toggle while editing old world budgets
    }
    this.forecastEnabled = this.data.budgetData?.forecastEnabled || false;
    this.initForm(this.data.budgetData);
     // Listen to toggle changes and update the service
     this.form.get('forecastEnabled')?.valueChanges
     .pipe(takeUntil(this.destroy$))
     .subscribe((value: boolean) => {
      if(value) {
        this.forecastEnabled = value;
      }
      else if(this.editMode) { // Only show this Disable Forecast popup on editing a budget
        this.captchaInput = '';
        this.modalService.openInitialUpdatePopup(this.modalData, this.captchaTemplate, this.redHeader, 'modal-with-large-content').subscribe((result) => {
          if (result) {
            this.forecastEnabled = value;
          }
          else {
            this.form.patchValue({ forecastEnabled: true });
          }
        });
      }
    });

  }

  onCaptchaInputChange() {
    this.modalData.buttons[0].disabled = this.captchaInput !== 'DISABLE';
  }

  private initForm(data: BudgetEditDialogFormData): void {
    const defaultDate = new Date();
    defaultDate.setMonth(0); // January is month 0
    defaultDate.setDate(1); // Set the day to 1
    const formConfig = {
      name: [data?.name || '', Validators.compose([Validators.required, Validators.maxLength(this.MAX_TEXT_INPUT_LENGTH)])],
      owner: [data?.owner || this.data.accountOwnerId, Validators.required],
      amount: [data?.amount || 0],
      type: [{ value: data?.type || this.timeframeTypes[0].id, disabled: this.editMode }, Validators.required],
      startDate: [data?.startDate || defaultDate, Validators.required],
      cegStatusesEnabled: [this.setCEGStatusToggleValue(data?.cegStatusesEnabled, this.data.isDefaultCEGCompany)],
      forecastEnabled: [data?.forecastEnabled || false], // Default value
    };
    this.form = this.fb.group(formConfig);
  }

  private setCEGStatusToggleValue(budgetCEGStatus: boolean, companyCEGStatus: boolean): { value: boolean, disabled: boolean } | boolean {
    
    if(typeof budgetCEGStatus !== 'boolean') {
      return { value: true, disabled: true };
    }
    const getToggleStatus = (budgetStatus: boolean, companyStatus: boolean): boolean => {
      return typeof budgetStatus === 'boolean' ? budgetStatus : companyStatus;
    }
    return {
      value: getToggleStatus(budgetCEGStatus, companyCEGStatus),
      disabled: getToggleStatus(budgetCEGStatus, companyCEGStatus)
    };
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }

    if(this.forecastEnabled && !this.form.get('cegStatusesEnabled')?.value) {
      return; // Don't allow to save if forecast is enabled for the old world budget save
    }
    // On Save/Edit setting the forecast enablement state for the budget
    this.forecastBudgetService.setForecastEnabled(this.forecastEnabled);
    this.forecastBudgetService.setManagePageForecastConfig(this.forecastEnabled);
    this.dialogRef.close(this.form.getRawValue());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
