import { Component, Inject, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconHeaderModalService } from '../../services/modal-with-icon-header.service';
import { ModalContext } from '../../components/modal-with-icon-header/modal-with-icon-header.component';
import { CustomFieldsAdminService } from '../../../account/custom-fields/custom-fields.service';
import { customFieldDetails, customFieldList, customFieldOptions } from '../../../account/custom-fields/custom-fields.type';
import { FormControl } from '@angular/forms';
import { CustomFieldsService } from '../../../budget-object-details/components/custom-fields/custom-field.service';
import { finalize, map } from 'rxjs';

@Component({
  selector: 'edit-custom-fields-modal',
  styleUrls: ['./edit-custom-fields-modal.component.scss'],
  templateUrl: './edit-custom-fields-modal.component.html'
})
export class EditCustomFieldsModalComponent implements AfterViewInit {
  @ViewChild('customFieldsTemplate') customFieldsTemplate!: TemplateRef<any>;
  modalData!: ModalContext;
  customFieldsList: customFieldList[] = [];
  selectedCustomField!: customFieldList;
  selectedCustomFieldoptions: customFieldOptions[] = [];
  config: any = {};
  selectedValues: FormControl = new FormControl();
  isLoading: boolean = false;
  selectedCustomFieldDetails: customFieldDetails | null = null;


  constructor(
    private readonly dialogRef: MatDialogRef<EditCustomFieldsModalComponent>,
    private modalService: IconHeaderModalService,
    private readonly customFieldsService: CustomFieldsAdminService,
    private readonly service: CustomFieldsService,
    @Inject(MAT_DIALOG_DATA) public data: editCFModalContext
  ) {}

  ngOnInit(): void {
    
    this.modalData = {
      title: 'Edit Custom Fields',
      content: '',
      icon: {
        prefix: 'fas',
        name: 'list-dropdown'
      },
      buttons: [
        { text: 'Save', color: 'primary', disabled: true }
      ]
    };

    this.fetchCustomFields();
  }

  fetchCustomFields() {
    this.isLoading = true;
    this.customFieldsService.getCFList({ company: this.data.company }).pipe(
      map((res) => 
        res.filter(field => this.data.isExpense ? field.for_expense : (field.for_campaign || field.for_program))
      )
    ).subscribe(filteredFields => {
      this.customFieldsList = filteredFields;
      if (this.customFieldsList.length) {
        this.selectedCustomField = this.customFieldsList[0];
        this.handleCustomFieldChange({ value: this.selectedCustomField });
      }
      else {
        this.isLoading = false;
      }
    }, error => {
      console.error('Error fetching custom fields:', error);
    });
  }

  handleCustomFieldChange(event: any) {
    this.selectedCustomField = event.value;
    this.selectedValues.setValue([]);
    this.selectedValues.markAsUntouched();
    this.resetDropdownState();
  
    this.isLoading = true;
    
    const mapping_type = this.data.isExpense ? 'Expense' : 'Campaign';

    this.service.fetchDropdownOptions(this.data.company, null, mapping_type).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      (res) => {
        this.config = res.find((cf: any) => cf.id === this.selectedCustomField.id);
      },
      (error) => {
        console.error('Error fetching custom field details:', error);
        this.config = {};
      }
    );
  }
  
  onDropdownStateChanged(e) {
    this.selectedValues.setValue(e);
    this.resetDropdownState();
  }

  resetDropdownState() {    
    if (this.selectedValues.value?.length || this.selectedValues.value === null) {
      this.modalData.buttons[0].disabled = false;
    } else {
      this.modalData.buttons[0].disabled = true;
    }
  }
  
  ngAfterViewInit(): void {
    if (!this.customFieldsTemplate) {
      console.error('customFieldsTemplate is not initialized');
      return;
    }
  
    this.modalService
      .openInitialUpdatePopup(this.modalData, this.customFieldsTemplate, false, 'dropdownWithRightButton')
      .subscribe((result) => {
        if (!result) {
          this.dialogRef.close();
          return;
        }
        this.isLoading = true;
  
        const selectedValues = Array.isArray(this.selectedValues.value)
          ? this.selectedValues.value.map(v => this.config.optionValueIdMapping[v]?.id)
          : this.config.optionValueIdMapping[this.selectedValues.value]?.id
            ? [this.config.optionValueIdMapping[this.selectedValues.value].id]
            : [];
  
        this.customFieldsService.bulkUpdateCF({
          mapping_type: this.data.isExpense ? 'Expense' : 'Campaign',
          company: this.data.company,
          map_ids: {
            [this.data.isExpense ? 'expense' : 'campaign']: this.data.selectedItems
          },
          cf_data: {
            [this.selectedCustomField.id]: selectedValues
          }
        }).subscribe(
          () => {
            this.isLoading = false;
            this.dialogRef.close(true);
          },
          (error) => {
            console.error('Error updating custom field values:', error);
            this.isLoading = false;
            this.dialogRef.close(false);
          }
        );
      });
  }
  
}

export interface editCFModalContext {
  company: number;
  isExpense: boolean;
  content: string;
  selectedItems: number[];
}