import { Injectable, inject } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditLogDO, AuditLogService } from './backend/audit-log.service';
import { HistoryObjectLogType } from '../types/history-object-log-type.type';
import { AuditLogParams } from '../types/audit-log-params.interface';
import { AuditLogOperation } from '../enums/audit-log-operation.enum';
import { AuditLogObjectsHistoryParams } from '../types/audit-log-objects-history-params.interface';
import { PfmV3Service } from './base/pfm-v3.service';

export interface HistoryItem {
  operation: string;
  object: {
    id: number;
    name: string;
    type: string;
  },
  parent?: {
    name: string;
    type: string;
  };
  createdDate: string;
}

export interface ObjectOperationLogs<T> {
  [operation: string]: {
    [objectType: string]: T[];
  };
}

const HISTORY_LIMIT = 20;
const RECENT_OBJECTS_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private v3APIManager = inject(PfmV3Service);
  private readonly objectOperationLogs = new BehaviorSubject<ObjectOperationLogs<HistoryItem>>(null);
  private readonly history = new BehaviorSubject<HistoryItem[]>(null);
  public history$ = this.history.asObservable();
  public objectOperationLogs$ = this.objectOperationLogs.asObservable();

  private endpoints = {
    recently_added: () => `budget/recently_created/`,
  }

  historyLogTypes: {[objType: string]: string};

  constructor(private auditLogService: AuditLogService, private config: Configuration) {
    const { campaign, program, expense } = config.OBJECT_TYPES;
    this.historyLogTypes = { campaign, program, expense, metricMapping: 'MetricMapping' };
  }

  private fetchHistory(options: Partial<AuditLogParams>) {
    return this.auditLogService.objectsViewHistory(options)
      .pipe(
        map(data => data.map(entry => this.processLogData(entry)))
      );
  }

  private processObjectOperationLogsResponse(
    response: ObjectOperationLogs<AuditLogDO>,
    operation: AuditLogOperation
  ): Record<string, HistoryItem[]> {
    const responseData = response[operation] || {};
    const result = {};

    Object.keys(responseData).forEach(objectType => {
      result[objectType] = (responseData[objectType] || []).map(this.processLogData);
    });

    return result;
  }

  private patchOperationLogsValue(response: ObjectOperationLogs<AuditLogDO>, operation: AuditLogOperation, objectType: string) {
    let objectOperationLogs = this.objectOperationLogs.getValue();
    const updatedLogs = response?.[operation]?.[objectType] || [];

    if (!objectOperationLogs) {
      objectOperationLogs = {
        [operation]: {}
      };
    }
    return {
      ...objectOperationLogs,
      [operation]: {
        ...objectOperationLogs[operation],
        [objectType]: updatedLogs.map(auditLog => this.processLogData(auditLog))
      }
    };
  }

  public loadHistory(companyId: number, userId: number, budgetId: number, limit = HISTORY_LIMIT) {
    this.fetchHistory({
      company: companyId,
      user: userId,
      budget: budgetId,
      limit
    }).subscribe(data => this.history.next(data));
  }

  public loadObjectOperationLogs(userId: number, budgetId: number, segmentIds: number[] = [], limit = RECENT_OBJECTS_LIMIT) {
    const { OBJECT_TYPES } = this.config;
    const logTypes = [OBJECT_TYPES.campaign, OBJECT_TYPES.program, OBJECT_TYPES.expense].join(',');
    const viewed$ = this.auditLogService.getObjectsHistory({
      limit,
      user: userId,
      budget: budgetId,
      operations: AuditLogOperation.Viewed,
      log_types: logTypes
    }).pipe(
      map(result => this.processObjectOperationLogsResponse(result, AuditLogOperation.Viewed))
    );

    this.objectOperationLogs.next(null);

    viewed$.pipe(
      map((viewedData) => {
        return {
          [AuditLogOperation.Viewed]: viewedData,
          [AuditLogOperation.Created]: {}
        } as ObjectOperationLogs<HistoryItem>;
      })
    ).subscribe(result => {
      this.objectOperationLogs.next(result);
      this.refreshRecentlyAdded(budgetId, OBJECT_TYPES.campaign as HistoryObjectLogType);
      this.refreshRecentlyAdded(budgetId, OBJECT_TYPES.program as HistoryObjectLogType);
      this.refreshRecentlyAdded(budgetId, OBJECT_TYPES.expense as HistoryObjectLogType);
    });
  }

  public refreshRecentlyViewed(userId: number, budgetId: number, objType: HistoryObjectLogType) {
    const params: Partial<AuditLogObjectsHistoryParams> = {
      limit: RECENT_OBJECTS_LIMIT,
      user: userId,
      budget: budgetId,
      operations: AuditLogOperation.Viewed,
      log_types: objType
    };

    this.auditLogService.getObjectsHistory(params)
      .pipe(
        map((data: ObjectOperationLogs<AuditLogDO>) => this.patchOperationLogsValue(data, AuditLogOperation.Viewed, objType))
      )
      .subscribe(
        data => this.objectOperationLogs.next(data)
      );
  }

  public refreshRecentlyAdded(
    budgetId: number, objLogType: HistoryObjectLogType
  ) {
    this.v3APIManager.get(
      this.endpoints.recently_added(),
      {
        limit: RECENT_OBJECTS_LIMIT,
        budget: budgetId,
        artefact: objLogType
      }
    ).pipe(
      map((data) => this.patchOperationLogsValue(
        this.massageRecentlyAddedResponse(data,objLogType),
        AuditLogOperation.Created,
        objLogType
      ))
    ).subscribe(data => {
      this.objectOperationLogs.next(data)
    })
  }

  private massageRecentlyAddedResponse(data, objType): ObjectOperationLogs<AuditLogDO> {
    return {
      [AuditLogOperation.Created]: {
        [objType]: data
      }
    };
  }

  private processLogData(auditLog: AuditLogDO): HistoryItem {
    const objectName = auditLog.name;
    const item: HistoryItem = {
      operation: auditLog.operation,
      object: {
        id: auditLog.instance_id,
        name: objectName,
        type: auditLog.log_type,
      },
      createdDate: auditLog.date
    }
    if (auditLog.parent) {
      item.parent = {
        type: auditLog.parent.object_type,
        name: auditLog.parent.name,
      }
    }
    return item;
  }

  get objectOperationLogsData() {
    return this.objectOperationLogs.value;
  }
}
