import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  ManageTableBudgetColumn,
  ManageTableBudgetColumnName,
  ManageTableTotalValues
} from '@manage-ceg/types/manage-ceg-page.types';
import { BehaviorSubject, finalize, Observable, Subject, takeUntil } from 'rxjs';
import { BudgetAllocationCellGesturesEvent } from 'app/budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { messages } from 'app/budget-object-details/messages';
import { BudgetAllocationsTableEvent, ActualSpendsData } from '../../budget-allocations-table/budget-allocations-table.type';
import { TableContentShadowsBaseComponent } from '@shared/directives/TableContentShadowsBase';
import { CampaignDetailsService } from '../../../services/campaign-details.service';
import { MatDialog } from '@angular/material/dialog';
import { BudgetAllocationService } from '../../../../shared/services/backend/budget-allocation.service';
import { ModalContext } from '../../../../shared/components/modal-with-icon-header/modal-with-icon-header.component';
import { IconHeaderModalService } from '../../../../shared/services/modal-with-icon-header.service';
import { UtilityService } from '../../../../shared/services/utility.service';
import { LocalStorageService } from '../../../../../../../common-lib/src/services/local-storage.service';
import { Configuration } from '../../../../app.constants';
import { EditBudgetModalService } from '../../../../shared/services/edit-budget-modal.service';
export interface TableAllocationRow {
  allocationId: number;
  timeframeId: number;
  shortName: string;
  locked: boolean;
  values: ManageTableTotalValues,
}

export interface TableAllocationsData {
  rows: TableAllocationRow[];
  totals: ManageTableTotalValues;
}

export interface OpenCellDetailsEvent {
  timeframeId?: number;
}

@Component({
  selector: 'ceg-budget-allocations-table',
  templateUrl: './ceg-budget-allocations-table.component.html',
  styleUrls: ['./ceg-budget-allocations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CegBudgetAllocationsTableComponent extends TableContentShadowsBaseComponent implements OnInit, OnDestroy {
  private readonly dialog = inject(MatDialog);
  protected restrictedFromDrop: Record<string, boolean> = {};
  protected updateShadows$ = new BehaviorSubject(null);
  protected manageTableBudgetColumnName = ManageTableBudgetColumnName;
  protected segmentlessAllocationTooltip = messages.SEGMENTLESS_ALLOCATION_CEG_TOOLTIP;
  private dragFromRow: TableAllocationRow;
  private allocationDialogShowed = false;
  @Input() tableRows: TableAllocationRow[];
  @Input() tableColumns: ManageTableBudgetColumn[];
  @Input() allocationTotals: Partial<ManageTableTotalValues>;
  @Input() campaignIsClosed: boolean;
  @Input() isReadOnlyMode: boolean;
  @Input() isSegmentlessCampaign: boolean;
  @Input() objectId: number;
  @Input() externalIntegrationType: { isExternal: boolean, integrationName: string };
  @Output() openExpenses = new EventEmitter<OpenCellDetailsEvent>();
  @Output() openBudgetAllocationDetails = new EventEmitter<OpenCellDetailsEvent>();
  @Output() onAllocationChange = new EventEmitter<BudgetAllocationsTableEvent[]>();
  @Output() onForecastAmountChange = new EventEmitter<BudgetAllocationsTableEvent[]>();
  @HostBinding('class.sticky-footer') footerIsSticky = true;
  @Input() objectType: string;  
  @Input() companyId: number;
  @Input() budgetId: number;
  @Input() isForecastEnabled = false;
  @ViewChild('budgetAllocationCell') budgetAllocationCell: ElementRef;

  selectedCell: { row: number, column: number } | null = null;
  selectedColumn: string;
  selectedRowTimeframe : number;
  selectedRowValue : number;
  private readonly destroy$ = new Subject<void>();
  private isBudgetEditModalOpen: boolean = false;
  
  clearSelection() {
    this.selectedCell = null;
  }

  iconByObjectType = {};
  accData: ActualSpendsData[];
  isLoading = false;
  
  constructor(private cdRef: ChangeDetectorRef,
    private budgetAllocationervice: BudgetAllocationService,
    private readonly modalService: IconHeaderModalService,
    private readonly utilityService: UtilityService,
    private readonly configuration : Configuration,
    private focusHandler: EditBudgetModalService
  ) {
      super();
  }

  ngOnInit(): void {

    this.iconByObjectType = {
      ['Campaigns']: ['far', 'rocket-launch'],
      ['ExpenseGroups']: ['fas', 'briefcase'],
      ['Expenses']: ['fas', 'coins'],
      ['ChildCampaigns']: ['far', 'rocket-launch'],
    };
  }

  highlightCell(row: number, column: number, selectedRow: TableAllocationRow, col: ManageTableBudgetColumn) {
      if(col.id === 'budget' || col.id === 'forecast' || this.objectType === 'Program') {
        return false;
      }
      if(selectedRow.values[col.id]){
        this.selectedColumn = col.id;
        this.accData = null;
        this.selectedRowTimeframe = selectedRow.timeframeId;
        this.selectedRowValue = selectedRow.values[col.id];
        if(this.selectedCell && this.selectedCell.row === row && this.selectedCell.column === column){
          this.selectedCell = null;
        }
        else{
          this.selectedCell = { row: row, column: column };
          this.isLoading = true;
          if(col.id === 'actual'){
            this.getActualSpends$(this.objectId, selectedRow.timeframeId, this.companyId, this.budgetId).pipe(
              takeUntil(this.destroy$),
              finalize(() => {
                this.isLoading = false;
                this.cdRef.detectChanges(); // Manually trigger change detection
              })
            ).subscribe(data => {
              this.accData = data;
            });
          }
          else{
            let isCommit = false;
            if(col.id === 'committed'){
              isCommit = true;
            }
            this.getRemainingAmount$(this.objectId, selectedRow.timeframeId, this.companyId, this.budgetId, isCommit).pipe(
              takeUntil(this.destroy$),
              finalize(() => {
                this.isLoading = false;
                this.cdRef.detectChanges(); // Manually trigger change detection
              })
            ).subscribe(data => {
              this.accData = data;
            });
          }
          
        }
      }
    }

  

  getActualSpends$(campaignId: number, periodId: number, companyId : number, budgetId : number): Observable<any> {
    const isGoal = this.objectType === 'Goal';
    return this.budgetAllocationervice.getExpenses(campaignId, periodId, companyId, budgetId, isGoal);
  }

  getRemainingAmount$(campaignId: number, allocationId: number, companyId : number, budgetId : number, isCommit : boolean): Observable<any> {
    const isGoal = this.objectType === 'Goal';
    return this.budgetAllocationervice.getRemainingAmount(campaignId, allocationId, companyId, budgetId, isGoal, isCommit);
  }

  public handleTableResize(): void {
    setTimeout(() => {
      this.updateShadows$.next(null);
    }, 700)
  }

  

  protected handleAllocationChange(amount: number, row: TableAllocationRow): void {
    const prevValue = row.values[ManageTableBudgetColumnName.Budget];

    if (prevValue !== amount) {
      this.onAllocationChange.emit([{
        amount,
        allocationId: row.allocationId,
        budgetTimeframeId: row.timeframeId
      }]);
    }
  }

  protected handleForecastAmountChange(amount: number, row: TableAllocationRow): void {
    const prevValue = row.values[ManageTableBudgetColumnName.Forecast];

    if (prevValue !== amount) {
      this.onForecastAmountChange.emit([{
        amount,
        allocationId: row.allocationId,
        budgetTimeframeId: row.timeframeId
      }]);
    }
  }
  

  protected handleDoubleClick($event: BudgetAllocationCellGesturesEvent, row: TableAllocationRow): void {
    this.onAllocationChange.emit([{
      amount: this.getTotalSpend(row),
      allocationId: row.allocationId,
      budgetTimeframeId: row.timeframeId
    }]);
  }

  protected handleOnDragStart($event: BudgetAllocationCellGesturesEvent, row: TableAllocationRow): void {
    this.dragFromRow = row;
  }

  protected handleOnDrop($event: BudgetAllocationCellGesturesEvent, row: TableAllocationRow): void {
    let startRowUpdatedBudgetAmount = 0;
    let endRowUpdatedBudgetAmount = 0;
    let startRowAvailableAmount = this.dragFromRow.values[ManageTableBudgetColumnName.Available];
    let endRowAvailableAmount = row.values[ManageTableBudgetColumnName.Available];
    let endRowBudgetAmount = row.values[ManageTableBudgetColumnName.Budget];
    let startRowTotalSpendAmount = this.getTotalSpend(this.dragFromRow)


    if(endRowAvailableAmount < 0) {
      let amountDiff = 0;

      if(startRowAvailableAmount > Math.abs(endRowAvailableAmount) ){
        amountDiff = Math.abs(endRowAvailableAmount)
      }
      else {
        amountDiff = startRowAvailableAmount 
      }
      endRowUpdatedBudgetAmount = endRowBudgetAmount + amountDiff
      startRowUpdatedBudgetAmount = startRowTotalSpendAmount + startRowAvailableAmount - amountDiff 
    }else {
      endRowUpdatedBudgetAmount = endRowBudgetAmount + startRowAvailableAmount
      startRowUpdatedBudgetAmount = startRowTotalSpendAmount
    } 


    this.onAllocationChange.emit([
      { amount: startRowUpdatedBudgetAmount, allocationId: this.dragFromRow.allocationId, budgetTimeframeId: this.dragFromRow.timeframeId },
      { amount: endRowUpdatedBudgetAmount, allocationId: row.allocationId, budgetTimeframeId: row.timeframeId, },
    ]);
  }

  private getTotalSpend(row: TableAllocationRow): number {
    return row.values[ManageTableBudgetColumnName.Actual] +
      (row.values[ManageTableBudgetColumnName.Planned] || 0) +
      (row.values[ManageTableBudgetColumnName.Committed] || 0);
  }

  protected onCellClick(timeframeId: number, columnId: ManageTableBudgetColumnName): void {
    if (!this.objectId) {
      return;
    }

    if (columnId === ManageTableBudgetColumnName.Actual) {
      this.openExpenses.emit({ timeframeId });
    } else if (columnId === ManageTableBudgetColumnName.Committed || columnId === ManageTableBudgetColumnName.Planned) {
      this.openBudgetAllocationDetails.emit({ timeframeId });
    }
  }

  public handleOnFocus(event: boolean, row: TableAllocationRow): void {
   
   const showManualChangeAllocationFlag = this.externalIntegrationType?.isExternal && event && !this.allocationDialogShowed;  
    const showIntegrationArtefactManualChangeConfirmationHandler = (cb ?: () => void) => {
      if (showManualChangeAllocationFlag) {
        this.allocationDialogShowed = true;
        CampaignDetailsService.showManualChangeConfirmDialog(this.externalIntegrationType.integrationName, this.dialog, cb);
      }
    }

    const budgetPopupKey = this.configuration.EDIT_TOTAL_BUDGET_POPUP_KEY_FOR_FORECAST;
    const dontShowBudgetEditPopup = LocalStorageService.getFromStorage(budgetPopupKey) || false;


    if(!this.isForecastEnabled || row.values[ManageTableBudgetColumnName.Budget] <= 0 || dontShowBudgetEditPopup){
      showIntegrationArtefactManualChangeConfirmationHandler();
    }
    else {
      this.focusHandler.handleOnFocus(event, row.values[ManageTableBudgetColumnName.Budget], this.isForecastEnabled, document.activeElement as HTMLInputElement,  showManualChangeAllocationFlag ? showIntegrationArtefactManualChangeConfirmationHandler: null);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
