<ng-container *ngFor="let column of budgetColumns">
  <div *ngIf="column.id === budgetColumnName.Budget || (budgetColumnsVisibility[column.id] && totalsSidebarState === sidebarState.FullWidth) || (budgetColumnsVisibility[column.id] && budgetColumnName.Forecast === column.id)">
    <div class="segment-breakdown" *ngIf="recordTotals">
      <div class="sub-row-item" [class.p-0]="column.id === budgetColumnName.Budget" [ngClass]="{ 'forecast-total-value-cell': column.id === budgetColumnName.Forecast, 'is-disabled': record.isClosed }">
        <budget-allocation-cell
          *ngIf="column.id === budgetColumnName.Budget; else totalValue"
          [allocated]="recordTotals[column.id].ownAmount"
          [remaining]="recordTotals[budgetColumnName.Available].ownAmount"
          [disabled]="isClosed"
          [showDifference]="true"
        >
        </budget-allocation-cell>
        <ng-template #totalValue>
          <ng-container *ngIf="column.id === budgetColumnName.Forecast && (!record.sharedCostRuleId && record.type !== 'Segment' && !record.isEditable || !ManageTableForecastRowTypes.includes(record.type)); else totalValueForManagePageColumn">
            {{ '-' }}
          </ng-container>
        </ng-template>
        <ng-template #totalValueForManagePageColumn>
          {{ recordTotals[column.id].ownAmount | absoluteNumber }}
        </ng-template>
      </div>
      <ng-container *ngIf="segmentRelated">
        <div class="sub-row-item"
         [ngClass]="{ 'forecast-total-value-cell': column.id === budgetColumnName.Forecast, 'is-disabled': record.isClosed }"
        *ngIf="segmentBreakdownConfig.campaignsAndPrograms">
        
          <ng-container *ngIf="column.id !== budgetColumnName.Forecast; else segmentRelatedForecast ">
              {{ recordTotals[column.id].campaignsAndPrograms | absoluteNumber }}
          </ng-container>
          
        </div>
        <ng-template #segmentRelatedForecast>
          {{ '-' }}
        </ng-template>

        <div class="sub-row-item"
         *ngIf="segmentBreakdownConfig.unallocated"
         [ngClass]="{ 'forecast-total-value-cell': column.id === budgetColumnName.Forecast, 'is-disabled': record.isClosed }"
         >
          <ng-container *ngIf="column.id !== budgetColumnName.Forecast; else segmentRelatedForecast ">
            {{ recordTotals[column.id].unallocated | absoluteNumber }}
          </ng-container>
      </div>
      </ng-container>
    </div>
  </div>
</ng-container>
