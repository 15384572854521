<div class="edit-cf-modal">
  <loader [isVisible]="isLoading"></loader>
</div>
<ng-template #customFieldsTemplate>
  <div class="edit-cf-content">
    <loader [isVisible]="isLoading"></loader>
    
    <div class="field-row">
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Custom Fields</mat-label>
        <mat-select [(value)]="selectedCustomField" (selectionChange)="handleCustomFieldChange($event)">
          
          <mat-select-trigger *ngIf="selectedCustomField">
            {{ selectedCustomField.name }}
          </mat-select-trigger>
          
          <mat-option *ngFor="let item of customFieldsList" [value]="item">
            {{ item.name }}
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>

    <custom-field-el *ngIf="config && config.options?.length" [config]="config" [hideIcon]="true"
      [control]="selectedValues" (stateChanged)="onDropdownStateChanged($event)">
    </custom-field-el>

    <div class="textarea">
      {{data.content}}
    </div>

  </div>
</ng-template>