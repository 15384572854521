<div class="budget-settings app-page">
  <page-header pageName="Plan Budget" isAdminPage="true">
    <fa-icon slot="icon" [icon]="['fas', 'sack-dollar']"></fa-icon>
    <div slot="name">
      <budget-name
        *ngIf="budgetData != null"
        [budgetName]="budgetData?.name"
        [budgetStatus]="budgetData?.status"
      ></budget-name>
    </div>
    <div slot="custom-items">
      <budget-owner
        *ngIf="budgetSettingsPageService.budgetOwner != null"
        [ownerName]="budgetSettingsPageService.budgetOwner?.name"
      ></budget-owner>
    </div>
  </page-header>
  <section class="page-content">
    <div class="page-container budget-settings-container">
      <budget-settings-dates-range [budget]="budgetData"></budget-settings-dates-range>
      <div class="budget-settings-summary">
        <budget-settings-summary-bar
          [budgetTotal]="budgetData?.amount"
          [allocatedTotal]="grandTotal?.total"
          [clickable]="editableBudget"
          [amountChanged]="amountChanged"
          (onEditClicked)="openEditModal()"
          (onRemainingChanged)="handleRemainingChanged($event)"
          [currency]="budgetCurrency"
        ></budget-settings-summary-bar>
        <div *ngIf="editableBudget"
             class="baseline-box"
             dynamicPortalTooltip
             [tooltipContext]="tooltipContext"
             [tooltipPosition]="tooltipPosition"
             [tooltipPositionCssClass]="'above'"
        >
          <mat-checkbox color="primary" [(ngModel)]="isBaselineChecked">Save as Baseline</mat-checkbox>
        </div>
      </div>
      <div class="budget-settings-bar">
        <div class="d-flex ai-center">
          <budget-manage-menu
            class="mr-2"
            *ngIf="editableBudget"
            [hasCurrentBudget]="budgetData != null"
          ></budget-manage-menu>
          <div *ngIf="showTodayDatePicker" class="d-flex ai-center">
            <mat-slide-toggle
              [ngModel]="todayFixedDateEnabled"
              [disabled]="!todayFixedDate"
              (change)="activateTodayFixedDate($event.checked)"
              disableRipple="true"
            ></mat-slide-toggle>
            <mat-form-field
              appearance="outline"
              class="p-form-field-outline no-validate data-picker">
              <mat-label>Override today’s date</mat-label>
              <input matInput
                     [ngModel]="todayFixedDate"
                     (dateChange)="onTodayDateChange($event.value)"
                     [matDatepicker]="pickerStartDate"
                     autocomplete="off"
                     [disabled]="!todayFixedDateEnabled"
                     (focus)="$event.target.select()"
                     (mousedown)="pickerStartDate.open()"/>
              <fa-icon class="data-picker-icon" [icon]="['fas', 'calendar-days']"></fa-icon>
              <mat-datepicker #pickerStartDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="save-controls">
          <button
            mat-stroked-button
            color="primary"
            (click)="budgetSettingsPageService.undoChanges()"
            [disabled]="!editableBudget || !hasUnsavedChanges"
          >
            Undo changes
          </button>
          <save-budget-button
            [status]="budgetData?.status"
            [disabled]="!editableBudget || (!hasUnsavedChanges && !isBaselineChecked)"
            [userIsBudgetOwner]="userIsBudgetOwner"
            (onStatusChanged)="onBudgetStatusChange($event)"
            (onSave)="saveBudget()"
          ></save-budget-button>
        </div>
      </div>
      <div class="budget-settings-table">
        <div class="budget-table-container">
          <ng-container *ngIf="budgetData">
            <budget-table
            [tableData]="tableData"
            [isCegBudget]="this.budgetSettingsPageService.isCegBudget"
            [budget]="budgetData"
            [timeframes]="budgetAllocations"
            [currency]="budgetCurrency"
            [getForecastStatus]="getForecastStatus"
            (onChange)="handleTableChanges($event)"
            (onSelectionChanged)="handleSelectionChanged($event)"
          ></budget-table>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>
