import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { BudgetAllocationsTableEvent } from '../budget-allocations-table/budget-allocations-table.type';
import { ManageTableBudgetColumn, ManageTableBudgetColumnName, ManageTableTotalValues } from '@manage-ceg/types/manage-ceg-page.types';
import { TableAllocationRow } from './ceg-budget-allocations-table/ceg-budget-allocations-table.component';
import { CampaignAllocation } from '@shared/types/budget-object-allocation.interface';
import { budgetAllocationColumns } from '@manage-ceg/constants/manage-ceg-page.constants';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { Configuration } from 'app/app.constants';
import { ViewSectionName } from 'app/dashboard/dashboard.types';

@Component({
  selector: 'ceg-budget-allocations',
  templateUrl: './ceg-budget-allocations.component.html',
  styleUrls: ['./ceg-budget-allocations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CegBudgetAllocationsComponent implements OnInit {
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly config = inject(Configuration);

  protected tableRows: TableAllocationRow[];
  private timeframesStore: Record<string, BudgetTimeframe>;
  private allocations: CampaignAllocation[];

  @Input() tableColumns: ManageTableBudgetColumn[] = [
    budgetAllocationColumns[ManageTableBudgetColumnName.Budget],
    budgetAllocationColumns[ManageTableBudgetColumnName.Actual],
    budgetAllocationColumns[ManageTableBudgetColumnName.Committed],
    budgetAllocationColumns[ManageTableBudgetColumnName.Planned],
  ];
  @Input() allocationTotals: Partial<ManageTableTotalValues>;
  @Input() isSegmentlessCampaign = false;
  @Input() isReadOnlyMode = false;
  @Input() campaignIsClosed: boolean;
  @Input() objectId: number;
  @Input() objectType: string;
  @Input() externalIntegrationType: { isExternal: boolean, integrationName: string };
  @Output() onChange = new EventEmitter<BudgetAllocationsTableEvent[]>();
  @Output() onForecastChange = new EventEmitter<BudgetAllocationsTableEvent[]>();
  @Input() companyId: number;
  @Input() budgetId: number;
  @Input() isForecastEnabled = false;

  private static mapAmountsToTableAllocationRows(
    allocations: CampaignAllocation[],
    timeframesStore: Record<string, BudgetTimeframe>
  ): TableAllocationRow[] {
    return allocations.reduce((data, allocation) => {
      const timeframeId = allocation.company_budget_alloc;
      const targetTf = timeframesStore[timeframeId];

      const row: TableAllocationRow = {
        allocationId: +allocation.id || null,
        timeframeId,
        shortName: targetTf.name.substring(0, 3),
        locked: targetTf.locked,
        values: {
          [ManageTableBudgetColumnName.Budget]: allocation.source_amount,
          [ManageTableBudgetColumnName.Actual]: allocation.source_actual,
          [ManageTableBudgetColumnName.Committed]: allocation.source_remaining_committed,
          [ManageTableBudgetColumnName.Planned]: allocation.source_remaining_planned,
          [ManageTableBudgetColumnName.Available]: allocation.available,
          [ManageTableBudgetColumnName.Forecast]: allocation.source_forecast_amount,
        },
      };
      data.push(row);
      return data;
    }, []);
  }

  @Input() set objectAllocations(allocations: CampaignAllocation[]) {
    this.allocations = allocations;
    this.setTableData();
  }

  @Input() set budgetTimeframes (timeframes: BudgetTimeframe[]) {
    this.timeframesStore = timeframes?.reduce((store, tf) => {
      store[tf.id] = tf;
      return store;
    }, {}) || null;
    this.setTableData();
  }

ngOnInit(): void {
  if (this.isForecastEnabled) {
    this.tableColumns.splice(1, 0, budgetAllocationColumns[ManageTableBudgetColumnName.Forecast]);
  }
}

  private setTableData(): void {
    if (!this.timeframesStore || !this.allocations) {
      return;
    }
    this.tableRows = CegBudgetAllocationsComponent.mapAmountsToTableAllocationRows(this.allocations, this.timeframesStore);
  }

  openExpensePage(timeframeId: number): Promise<boolean> {
    const objectTypes = this.config.OBJECT_TYPES;

    const viewMode = {
      [objectTypes.goal]: HierarchyViewMode.Goal,
      [objectTypes.campaign]: HierarchyViewMode.Campaign,
      [objectTypes.program]: HierarchyViewMode.Campaign
    };

    return this.appRoutingService.openExpenseListFromObjectDrawer(
      this.getFiltersForOpenCellDetails(timeframeId),
      viewMode[this.objectType]
    );
  }

  openManageCEGPage(timeframeId: number): Promise<boolean> {
    const objectTypes = this.config.OBJECT_TYPES;

    const viewMode = {
      [objectTypes.goal]: ViewSectionName.goals,
      [objectTypes.campaign]: ViewSectionName.campaigns,
      [objectTypes.program]: ViewSectionName.campaigns
    };

    return this.appRoutingService.openManageCEGPageFromDrawer(
      this.getFiltersForOpenCellDetails(timeframeId),
      viewMode[this.objectType]
    );
  }

  private getFiltersForOpenCellDetails(timeframeId: number): FilterSet {
    const objectTypes = this.config.OBJECT_TYPES;

    const objectFilterNames = {
      [objectTypes.goal]: FilterName.Goals,
      [objectTypes.campaign]: FilterName.Campaigns,
      [objectTypes.program]: FilterName.ExpenseBuckets
    };

    const filters: FilterSet = {};
    if (timeframeId) {
      filters[FilterName.Timeframes] = [timeframeId];
    }
    const objectFilterName = objectFilterNames[this.objectType];
    if (objectFilterName) {
      filters[objectFilterName] = [this.objectId];
    }

    return filters;
  }
}
