import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import {
  ManageCegTableRow,
  ManageCegTableSelectionState,
  ManageCegViewMode,
  ManagePageExportParams,
  ManagePageExportParamKeys,
  ManageTableViewConfig,
  TableConfigKeys
} from '../types/manage-ceg-page.types';

export class ManageCegTableHelpers {
  public static getBulkTargetsFromSelection(selection: ManageCegTableSelectionState): BulkActionTargets {
    return {
      goals: Array.from(selection.goals),
      campaigns: Array.from(selection.campaigns),
      expGroups: Array.from(selection.expGroups),
    };
  }

  public static getInitials(fullName: string): string {
    const names: string[] = fullName.split(" ");
    const initials: string = names
      .map((name: string) => name.charAt(0).toUpperCase())
      .join('');
    return initials;
  }

  public static getChildRecordIds(target: ManageCegTableRow, includeTarget = false): string[] {
    const result: string[] = [];

    if (includeTarget) {
      result.push(target.id);
    }
    target.loadedChildren.forEach(child => {
      result.push(child.id);
      if (child.loadedChildren?.length) {
        result.push(...ManageCegTableHelpers.getChildRecordIds(child));
      }
    });

    return result;
  }

  public static mapTableConfigToExportParams(
    exportParams: Partial<ManagePageExportParams>,
    tableConfig: ManageTableViewConfig,
    viewMode: ManageCegViewMode
  ): Partial<ManagePageExportParams> {
    const exportFields = {
      [TableConfigKeys.campaignsAndPrograms]: ManagePageExportParamKeys.showCampaignsAndPrograms,
      [TableConfigKeys.unallocated]: ManagePageExportParamKeys.showOtherExpenses,
      [TableConfigKeys.actual]: ManagePageExportParamKeys.showActualSpend,
      [TableConfigKeys.committedAndPlanned]: ManagePageExportParamKeys.showRemainingPlannedCommitted,
      [TableConfigKeys.separate]: ManagePageExportParamKeys.remainingPlannedCommittedSeparate,
      [TableConfigKeys.available]: ManagePageExportParamKeys.showAvailable,
      [TableConfigKeys.formula]: ManagePageExportParamKeys.rowsFormula,
      [TableConfigKeys.columnsFormula]: ManagePageExportParamKeys.columnsFormula,
      [TableConfigKeys.forecast]: ManagePageExportParamKeys.showForecast
    };
    const rowParams = [TableConfigKeys.campaignsAndPrograms, TableConfigKeys.unallocated, TableConfigKeys.formula];
    const configFields = { ...tableConfig.columns, ...tableConfig.segmentBreakdownData };
    return Object.keys(configFields).reduce((acc: ManagePageExportParams, key: TableConfigKeys) => {
      if (exportFields[key]) {
        acc[exportFields[key]] =
          viewMode !== ManageCegViewMode.Segments && rowParams.includes(key)
          ? false
          : configFields[key];
      }
      return acc;
    }, { ...exportParams })
  }
}
