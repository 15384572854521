import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldsService } from './custom-field.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'custom-field-el',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent {
  @Input() config: any; // Input property to receive the configuration object
  @Input() control: FormControl;
  @Input() hideIcon: boolean = false;

  @Output() stateChanged = new EventEmitter<string | string[]>(); // Output event emitter for state changes
  @Output() selectDropdownClosed = new EventEmitter<boolean>(); // Output event emitter for search text changes

  searchText: string = '';
  options: string[];
  filteredOptions: string[] = [];
  selectedValues: string[];
  isAllSelected: boolean = false;
  isIndeterminate: boolean = false;
  private destroy$ = new Subject<void>();
  isComponentVisible: boolean = true;

  constructor(
    private customFieldsService: CustomFieldsService,
    private cdr: ChangeDetectorRef
  ) { 
    this.customFieldsService.updateState$
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      if(this.config.isMultiSelect) {
        this.updateSelectAllState();
      }
    });
  }

  ngOnInit() {
    this.initializeOptions();
  }

  forceReRender() {
    this.isComponentVisible = false;
    setTimeout(() => {
      this.isComponentVisible = true;
      this.cdr.detectChanges();
    }, 0);
  }

  private initializeOptions() {
    this.options = this.config?.options || [];
    this.isAllSelected = false;
    this.filteredOptions = [...this.options];
    this.selectedValues = this.control?.value || [];
    
    if (this.config?.isMultiSelect) {
      this.updateSelectAllState();
    }
  }

  onSelectionChange(event: any) {
    const value = event.value; 
    if(this.config.isMultiSelect) {
      if(this.searchText) {
        
        this.selectedValues = [...new Set([...this.selectedValues, ...value]) ];
    
        this.filteredOptions.forEach(option => {
          if(this.selectedValues.includes(option)) {
            if(!value.includes(option)) {
              this.selectedValues = this.selectedValues.filter(v => v !== option);
            }
          }
        });
      }else {
        this.selectedValues = value;
      }
      this.stateChanged.emit(this.selectedValues);
    }else {
      this.stateChanged.emit(value);
    }
    if(this.config.isMultiSelect) {
      this.updateSelectAllState();  // Initialize the state of "Select All"
    }
  }

  onDropdownClosed(){
    this.searchText = ''; // Clear search text
    this.filteredOptions = this.options; // Reset filtered options
    if(this.config.isMultiSelect) {
      this.updateSelectAllState();  // computes the state of "Select All"
    }
    this.selectDropdownClosed.emit(true); // Emit event to parent component
  }
  
  filterOptionsByText(searchText: string) {
    this.searchText = searchText;
    if (searchText) {
      this.filteredOptions = this.options.filter(option =>
        option.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredOptions = this.options;
    }
    if(this.config.isMultiSelect) {
      this.updateSelectAllState();  // Initialize the state of "Select All"
    }
  }
  
  toggleSelectAll(event) {
    if(this.isAllSelected && !this.searchText && event) { return; }

    if (this.isAllSelected) {
      this.control.setValue([]);
    } else {
         const allOptions = this.filteredOptions;
         this.control.setValue(allOptions);
    }
    this.onSelectionChange({ value: this.control.value });
  }

  updateSelectAllState() {
    let selectedOptions = []
    if(this.control.value) {
      if(this.control.value[0]){
        selectedOptions = this.control.value;
      }else {
        selectedOptions = this.control.value.splice(1) || [];
      }
    }
    
    const filteredSelectedOptions = selectedOptions.filter(x => this.filteredOptions.includes(x))
    this.isAllSelected = this.filteredOptions.length === filteredSelectedOptions.length;
    this.isIndeterminate = filteredSelectedOptions.length > 0 && filteredSelectedOptions.length < this.filteredOptions.length;

  }

  private isFirstChange: boolean = true;

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFirstChange) {
      this.isFirstChange = false;
    } else {
      if (changes['config'] && changes['config'].currentValue !== changes['config'].previousValue) {
        this.forceReRender();
        this.initializeOptions();
      }
    }
  
    if (changes['control'] && changes['control'].currentValue !== changes['control'].previousValue) {
      this.selectedValues = this.control.value || [];
      if (this.config.isMultiSelect) {
        this.updateSelectAllState();
      }
    }
  }
  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
