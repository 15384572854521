import { inject, Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { PfmV2Service } from 'app/shared/services/base/pfm-v2.service';

@Injectable()
export class ManageCompanyApiService {
  private v2_api_manager = inject(PfmV2Service);

  private endpoints = {
    delete_stale_users: 'super_admin/delete_stale_users/',
    delete_stale_integrations: 'super_admin/delete_stale_integrations/',
    delete_stale_db_data: 'super_admin/delete_stale_db_data/'
  };

  public delete_stale_data() : Observable<any> {
    return forkJoin([
      this.v2_api_manager.delete<boolean>(this.endpoints.delete_stale_users, {}),
      this.v2_api_manager.delete<boolean>(this.endpoints.delete_stale_integrations, {}),
      this.v2_api_manager.delete<boolean>(this.endpoints.delete_stale_db_data, {})
    ] as any);
  }
}
