import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BudgetObjectDetailsService } from '../types/budget-object-details-service.interface';
import { ProgramDetailsState } from '../types/budget-object-details-state.interface';
import { Configuration } from 'app/app.constants';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { BudgetObjectCreationContext } from '../types/details-creation-context.interface';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { LightProgram, Program, ProgramDO } from 'app/shared/types/program.interface';
import { ProgramStateMapper } from './state-mappers/program-state-mapper.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ProgramAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { BudgetObjectTagsService } from './budget-object-tags.service';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { TasksService } from 'app/shared/services/backend/tasks.service';
import { TaskDO } from 'app/shared/types/task.interface';
import { SaveDetailsContext } from '../types/save-details-context.interface';
import { ChurnZeroService, EventName } from 'app/shared/services/churn-zero.service';
import { DialogAction, DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { DeepPartial } from 'app/shared/types/deep-partial.type';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { PendoEventName, PendoManagerService, PendoObjectType } from '@shared/services/pendo-manager.service';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { injectRemainingAmountsIntoAllocation } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { ExpenseDO } from '@shared/types/expense.interface';
import { sumAndRound } from '@shared/utils/common.utils';
import { BulkOperationResponse } from '@shared/types/bulk-operation-response.interface';
import { CFProgramDetailsContext } from '../components/custom-fields/custom-field.service';

export interface ProgramDetailsForm {
  name: string;
  segment: HierarchySelectItem;
  ownerId: number;
  glCode: number;
  poNumber: string;
  location: any;
  typeId: number;
  customType: string;
  notes: string;
  vendorId?: number;
  vendorName?: string;
  currencyCode?: string;
  amountStatus?: CEGStatus;
}

@Injectable()
export class ProgramDetailsService implements BudgetObjectDetailsService<ProgramDetailsState> {
  private readonly configuration = inject(Configuration);
  private readonly programService = inject(ProgramService);
  private readonly tagsManager = inject(BudgetObjectTagsService);
  private readonly expenseService = inject(ExpensesService);
  private readonly tasksService = inject(TasksService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly stateMapper = inject(ProgramStateMapper);
  private readonly churnZeroService = inject(ChurnZeroService);
  private readonly dialogManager = inject(BudgetObjectDialogService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly pendoManager = inject(PendoManagerService);

  /**
   * State properties to check updates for
   */
  private updatableStateProps = [
    'typeId',
    'name',
    'notes',
    'createdBy',
    'budgetId',
    'ownerId',
    'segment',
    'parentObject',
    'spreadSegmentToChildren',
    'spreadGLCodeToChildren',
    'spreadPONumberToChildren',
    'amount',
    'glCode',
    'poNumber',
    'amountStatus',
    'vendor',
    'vendorName',
    'currencyCode',
  ];
  /**
   * State properties to exclude from state diff checks
   */
  private statePropsToExclude = [
    'spreadSegmentToChildren',
    'spreadGLCodeToChildren',
    'spreadPONumberToChildren',
    'amount',
    'attachmentMappings',
    'vendorName'
  ];

  public createProgramDetailsState(
    program: Partial<ProgramDO>,
    tagMappings: TagMapping[],
    expenses: any[],
    tasks: TaskDO[] = []
  ): ProgramDetailsState {
    const state = this.stateMapper.dataObjectToState(program);

    return {
      ...state,
      tagMappings: tagMappings || [],
      expenses: expenses || [],
      spreadSegmentToChildren: false,
      spreadGLCodeToChildren: false,
      spreadPONumberToChildren: false,
      tasks
    } as ProgramDetailsState;
  }

  cloneObject(objectId: number): Observable<BudgetObjectCloneResponse> {
    return this.programService.cloneProgram(objectId);
  }

  deleteObject(objectId: number): Observable<void> {
    return this.programService.deleteProgram(objectId);
  }

  closeObject(objectId: number): Observable<ProgramDO> {
    return this.programService.updateProgram(objectId, { mode: ObjectMode.Closed })
      .pipe(
        switchMap(() => this.programService.getProgram(objectId))
      );
  }

  reopenObject(objectId: number): Observable<any> {
    return this.programService.updateProgram(objectId, { mode: ObjectMode.Open })
      .pipe(
        switchMap(() => this.programService.getProgram(objectId))
      );
  }

  moveToBudget(objectId: number, budgetId: number, companyId: number): Observable<any> {
    return this.programService.moveToBudget(objectId, budgetId, companyId);
  }

  loadDetails(companyId: number, budgetId: number, objectId: number, data: { isCEGMode: boolean }): Observable<ProgramDetailsState> {
    const isCEGMode = data.isCEGMode;
    return forkJoin([
      this.programService.getProgram(objectId).pipe(
        switchMap(program =>
          !isCEGMode ?
            of(program) :
            this.loadProgramAllocationAmounts(budgetId, objectId, program.source_currency, program.program_allocations).pipe(map(() => program))
        )
      ),
      this.tagsManager.getTagMappings(companyId, objectId, this.configuration.OBJECT_TYPES.program),
      this.getProgramExpenses$(companyId, budgetId, objectId),
      this.tasksService.getTasks(companyId, { program: objectId })
    ]).pipe(map(
      ([program, tagMappings, expenses, tasks]) => (
        this.createProgramDetailsState(program, tagMappings, expenses, tasks)
      )
    ));
  }

  getProgramExpenses$(companyId: number, budgetId: number, programId: number) {
    return this.expenseService.getExpenses(
      { company: companyId, budget: budgetId, program_ids: programId.toString(), include_nested: true }
    );
  }

  loadProgramAllocationAmounts(
    budgetId: number,
    objectId: number,
    currencyCode: string,
    programAllocations: ProgramAllocation[]
  ): Observable<ProgramAllocation[]> {
    return this.programService.getProgramAmountsByTimeframes(budgetId, objectId, currencyCode).pipe(
      map(amounts => {
        injectRemainingAmountsIntoAllocation(amounts, programAllocations);
        return programAllocations;
      })
    );
  }

  reloadChildObjects(companyId: number, state: ProgramDetailsState) {
    const { budgetId, objectId } = state;

    return this.expenseService.getExpenses({
      company: companyId,
      budget: budgetId,
      program_ids: objectId.toString(),
      include_nested: true
    })
      .pipe(
        tap((expenses) => {
          state.expenses = expenses || [];
        })
      );
  }

  updateExpenses(companyId: number, state: ProgramDetailsState) {
    const { budgetId, objectId } = state;

    return forkJoin([
      this.programService.getProgram(objectId),
      this.expenseService.getExpenses(
        { company: companyId, budget: budgetId, campaign_ids: objectId.toString(), include_nested: true }
      )
    ]).pipe(
      tap(([program, expenses]) => {
        state.expenses = expenses;
        state.statusTotals = program.status_totals;
      })
    );
  }

  saveDetails(
    prevObjectDetails: ProgramDetailsState,
    newObjectDetails: ProgramDetailsState,
    saveDetailsCtx?: SaveDetailsContext,
    CFDetailsCtx?: CFProgramDetailsContext
  ): Observable<ProgramDO> {
    if (prevObjectDetails) {
      return this.updateDetails(prevObjectDetails, newObjectDetails, saveDetailsCtx, CFDetailsCtx).pipe(
        tap(() => {
          this.churnZeroService.trackEvent(EventName.ExpenseBucketUpdate);
          this.pendoManager.track(PendoEventName.ObjectUpdated, {
            type: PendoObjectType.ExpenseGroup
          });
        })
      );
    } else {
      return this.createDetails(newObjectDetails, saveDetailsCtx, CFDetailsCtx).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectCreated, {
            type: PendoObjectType.ExpenseGroup
          })
        )
      );
    }
  }

  initDetails(context: BudgetObjectCreationContext, data: Partial<ProgramDO>): Observable<ProgramDetailsState> {
    const program = {...data};
    const state = this.createProgramDetailsState(program, [], [], []);
    const contextParent = context?.parent;
    const { OBJECT_TYPES } = this.configuration;

    if (context?.suggestedName) {
      state.name = context.suggestedName;
    }
    state.segment =
      context && (context.segmentId || context.sharedCostRuleId) ?
        { segmentId: context.segmentId, sharedCostRuleId: context.sharedCostRuleId } :
        { segmentId: null, sharedCostRuleId: null };

    state.typeId = context && context.objectTypeId || null;
    state.glCode = context && context.glCodeId || null;
    state.vendor = context && context.vendorId || null;
    state.ownerId = context && context.ownerId || null;

    if (contextParent) {
      state.parentObject = contextParent;
      state.goalId = contextParent?.type === OBJECT_TYPES.goal ? contextParent.id : null;
      state.campaignId = contextParent?.type === OBJECT_TYPES.campaign ? contextParent.id : null;
    }

    return of(state);
  }

  private updateDetails(
    prevState: ProgramDetailsState,
    newState: ProgramDetailsState,
    saveDetailsCtx?: SaveDetailsContext,
    CFDetailsCtx?: CFProgramDetailsContext
  ): Observable<ProgramDO> {
    const stateDiff =
      this.budgetObjectDetailsManager.getAllocatableStateDiff(
        prevState,
        newState,
        this.updatableStateProps
      ) as Partial<ProgramDetailsState>;
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const programPayload = this.stateMapper.stateToDataObject(stateDiff, isCurrentBudgetWithNewCEGStructure);
    const allocationsDiff = stateDiff.allocations;

    const isBudgetSuppressMode = Boolean(saveDetailsCtx?.suppressMode);

    // Allocations are to be updated first to make sure SCR rules are applied correctly!
    return this.updateAllocations(newState, allocationsDiff || [], newState.companyId, newState.objectId).pipe(
      switchMap(updatedAllocs => this.programService.updateProgramAndFetchResult(newState.objectId, programPayload, CFDetailsCtx?.customFieldsStateDiff)
        .pipe(
          tap(updatedProgram => this.updateChildExpenses(newState)),
          tap(updatedProgram => newState.statusTotals = { ...updatedProgram.status_totals }),
          map(updatedProgram => {
            this.budgetObjectDetailsManager.patchAllocations(newState.allocations, updatedAllocs);
            return updatedProgram;
          })
        )
      ),
      switchMap((updatedProgram: ProgramDO) =>
        this.applySharedCostRuleToUpdatedProgram(
          isBudgetSuppressMode,
          newState.objectId,
          newState.segment.sharedCostRuleId,
          prevState.segment.sharedCostRuleId
        ).pipe(
          map(() => updatedProgram)
        )
      )
    );
  }

  private createDetails(state: ProgramDetailsState, saveDetailsCtx?: SaveDetailsContext, CFDetailsCtx?: CFProgramDetailsContext): Observable<ProgramDO> {
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const payload: DeepPartial<ProgramDO> = this.stateMapper.stateToDataObject(state, isCurrentBudgetWithNewCEGStructure);
    const isBudgetSuppressMode = Boolean(saveDetailsCtx?.suppressMode);
    payload.program_allocations = this.budgetObjectDetailsManager.getCreateAllocationsPayload(state);

    return this.programService.createProgram(payload, CFDetailsCtx?.customFieldsStateDiff)
      .pipe(
        switchMap(createdProgram =>
          this.applySharedCostRuleToAddedProgram(
            isBudgetSuppressMode,
            createdProgram.id,
            state.segment && state.segment.sharedCostRuleId
          ).pipe(
            map(() => createdProgram)
          )
        ),
        tap((createdProgram: ProgramDO) => {
          this.budgetObjectDetailsManager.patchState(state, createdProgram);
          this.budgetObjectDetailsManager.patchAllocations(state.allocations, createdProgram.program_allocations);
          this.budgetObjectDetailsManager.initStatusTotals(state, createdProgram.amount);
        })
      );
  }

  private updateAllocations(
    state: ProgramDetailsState,
    targetAllocations: ProgramAllocation[],
    companyId?: number,
    objectId?: number
  ): Observable<Partial<ProgramAllocation>[]> {
    const getAllocationsPayload = (alloc: ProgramAllocation, create = false) => {
      const allocPayload: Partial<ProgramAllocation> = {
        source_amount: alloc.source_amount,
        mode: alloc.mode,
        program: alloc.program || objectId,
        source_forecast_amount: alloc.source_forecast_amount
      };

      if (create) {
        allocPayload.company = companyId;
        allocPayload.company_budget_alloc = alloc.company_budget_alloc;
      }

      return allocPayload;
    };

    return targetAllocations && targetAllocations.length ?
      forkJoin(
        state.allocations.map((stateAllocation) => {
          const stateAllocationExists = !!stateAllocation.id;
          const payload = getAllocationsPayload(stateAllocation, !stateAllocationExists);
          if (stateAllocationExists) {
            const targetAllocation = targetAllocations.find(alloc => alloc.id === stateAllocation.id);
            if (!targetAllocation) {
              return of(null);
            }

            return this.programService.updateProgramAllocation(stateAllocation.id, payload);
          } else {
            // create new allocation if not exists
            return this.programService.addProgramAllocation(payload);
          }
        })
      ) :
      of(null);
  }

  preFillStateAllocations(
    state: ProgramDetailsState,
    budgetTimeframes: BudgetTimeframe[] = [],
    options: { suppressMode: boolean; CEGMode: boolean; }
  ) {
    if (!options.suppressMode) {
      state.allocations = this.budgetObjectDetailsManager.initBudgetObjectAllocations(
        state.allocations,
        budgetTimeframes,
        options.CEGMode
      );
    }
  }

  fillAllocationsFromExpenses(
    state: ProgramDetailsState,
    budgetTimeframes: BudgetTimeframe[] = [],
    expenses: ExpenseDO[]
  ): void {
    const amountMap = {};
    for (const expense of expenses) {
      if (amountMap[expense.company_budget_alloc]) {
        amountMap[expense.company_budget_alloc] += expense.actual_amount;
      } else {
        amountMap[expense.company_budget_alloc] = expense.actual_amount;
      }
    }

    state.allocations.forEach(allocation => {
      if (amountMap[allocation.company_budget_alloc]) {
        allocation.amount = amountMap[allocation.company_budget_alloc];
        allocation.source_amount = amountMap[allocation.company_budget_alloc];
        allocation.actual_amount = amountMap[allocation.company_budget_alloc];
        allocation.source_actual = amountMap[allocation.company_budget_alloc];
      }
    });
    state.sourceAmount = state.allocations.reduce((sum: number, alloc) => sumAndRound(alloc.amount, sum), 0);
  }

  private applySharedCostRuleToAddedProgram(
    isBudgetSuppressMode: boolean,
    programId: number,
    sharedCostRuleId?: number
  ): Observable<void> {
    return !isBudgetSuppressMode && sharedCostRuleId ?
      this.programService.split(programId) :
      of(null);
  }

  private applySharedCostRuleToUpdatedProgram(
    isBudgetSuppressMode: boolean,
    programId,
    newRuleId?: number,
    prevRuleId: number = null
  ): Observable<void> {
    return !isBudgetSuppressMode && newRuleId != null && newRuleId === prevRuleId ?
      this.programService.split(programId) :
      of(null);
  }

  hasChanges(prevState: ProgramDetailsState, currentState: ProgramDetailsState) {
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    const statePropsToExclude = [...this.statePropsToExclude];
    if (!isCurrentBudgetWithNewCEGStructure) {
      statePropsToExclude.push('amountStatus');
    }
    return this.budgetObjectDetailsManager.hasChanges(prevState, currentState, statePropsToExclude);
  }

  logObjectView(objectId: number): Observable<void> {
    return this.programService.logProgramView(objectId);
  }

  updateChildExpenses(updatedState: ProgramDetailsState) {
    const { spreadGLCodeToChildren,  spreadPONumberToChildren } = updatedState;
    if (spreadGLCodeToChildren || spreadPONumberToChildren) {
      const payload: any = { ids: updatedState.expenses.map(exp => exp.id) };
      if (spreadGLCodeToChildren) {
        payload.gl_code = updatedState.glCode;
      }
      if (spreadPONumberToChildren) {
        payload.expense_po_no = updatedState.poNumber;
      }
      this.expenseService.updateMultiExpenses(payload).subscribe(
        res => {}
      );
    }
  }

  updateSelectedExpensesParent(programId: number, selectedExpenses: ExpenseDO[]): Observable<BulkOperationResponse<ExpenseDO>> {
    return this.expenseService.updateMultiExpenses({
      ids: selectedExpenses.map(exp => exp.id),
      program: programId,
      campaign: null
    });
  }

  public confirmSpreadValueToChildren(fieldName): Observable<boolean> {
    return new Observable(observer => {
      const handler = (value: boolean) => {
        observer.next(value);
        observer.complete();
      };
      const keepAction: DialogAction = {
        label: 'No',
        type: DIALOG_ACTION_TYPE.STROKED,
        handler: () => handler(false)
      };
      const updateAction: DialogAction = {
        label: 'Yes',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => handler(true)
      };

      const modalActions = [ keepAction, updateAction ];
      const modalText = `Would you like to apply this ${fieldName} for all child expenses?`;

      this.dialogManager.openConfirmationDialog({
        title: `Update ${fieldName}`,
        content: modalText,
        actions: modalActions
      });
    });
  }

  public getProgram(programId: number, programs: Program[] | LightProgram[]): Observable<Program> {
    const program = programs.find(prg => prg.id === programId);
    if (!program) {
      return of(null);
    }
    if (!program.isShort) {
      return of(program as Program);
    }
    return this.programService.getProgram(programId).pipe(
      map(programDO => this.budgetDataService.convertProgram(programDO))
    );
  }

  public getProgramDO(programId: number): Observable<ProgramDO> {
    return this.programService.getProgram(programId);
  }
}
