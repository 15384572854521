import { SpendingTimelineComponent } from './../dashboard/spending-timeline/spending-timeline.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimeframesComponent } from './components/timeframes/timeframes.component';
import { IconCancelComponent } from './components/icons/icon-cancel/icon-cancel.component';
import { IconFlameComponent } from './components/icons/icon-flame/icon-flame.component';
import { IconTrashComponent } from './components/icons/icon-trash/icon-trash.component';
import { IconSortingComponent } from './components/icons/icon-sorting/icon-sorting.component';
import { IconAddObjectsComponent } from './components/icons/icon-add-objects/icon-add-objects.component';
import { IconLockComponent } from './components/icons/icon-lock/icon-lock.component';
import { IconSearchComponent } from './components/icons/icon-search/icon-search.component';
import { IconTagsComponent } from './components/icons/icon-tags/icon-tags.component';
import { IconGripComponent } from './components/icons/icon-grip/icon-grip.component';
import { IconPlannuhComponent } from './components/icons/icon-plannuh/icon-plannuh.component';
import { IconSalesforceComponent } from './components/icons/icon-salesforce/icon-salesforce.component';
import { ExpandCollapseComponent } from './components/expand-collapse/expand-collapse.component';
import { OptionsSelectComponent } from './components/options-select/options-select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SortDirectionsConstants } from './constants/sort-directions.constants';
import { KeyCodesConstants } from './constants/key-codes.constants';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { AuditLogsComponent } from './components/audit-logs/audit-logs.component';
import { ShowHideToggleComponent } from './components/show-hide-toggle/show-hide-toggle.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { ScrollDetectorDirective } from './directives/scroll-detector.directive';
import { ModalWindowComponent } from './modals/modal-window/modal-window.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
import { TagsControlComponent } from './components/tags-control/tags-control.component';
import { IconFavoriteComponent } from './components/icons/icon-favorite/icon-favorite.component';
import { IconTuneComponent } from './components/icons/icon-tune/icon-tune.component';
import { DropDownArrowComponent } from './components/icons/drop-down-arrow/drop-down-arrow.component';
import { IconSharedComponent } from './components/icons/icon-shared/icon-shared.component';
import { SegmentsRulesSelectComponent } from './components/segments-rules-select/segments-rules-select.component';
import { SharedRuleTooltipComponent } from './components/shared-rule-tooltip/shared-rule-tooltip.component';
import { TooltipContainerComponent } from './components/tooltip-container/tooltip-container.component';
import { SimpleTooltipComponent } from './components/simple-tooltip/simple-tooltip.component';
import { ScrollOverlapDirective } from './directives/scroll-overlap.directive';
import { IconDownloadComponent } from './components/icons/icon-download/icon-download.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { BudgetStatusIconComponent } from './components/icons/budget-status-icon/budget-status-icon.component';
import { PlClickOutsideDirective } from './directives/pl-click-outside.directive';
import { MaintenanceTooltipComponent } from './components/maintenance-tooltip/maintenance-tooltip.component';
import { IconDeathStarComponent } from './components/icons/icon-death-star/icon-death-star.component';
import { IconMetricsComponent } from './components/icons/icon-metrics/icon-metrics.component';
import { IconMetricsDeleteComponent } from './components/icons/icon-metrics-delete/icon-metrics-delete.component';
import { IconMetricsDoneComponent } from './components/icons/icon-metrics-done/icon-metrics-done.component';
import { BarGraphComponent } from 'app/dashboard/bar-graph/bar-graph.component';
import { SmallNumber } from './pipes/smallnumber.pipe';
import { BudgetAmountsSummaryComponent } from 'app/dashboard/budget-amounts-summary/budget-amounts-summary.component';
import { GetInitials } from './directives/get-initials.directive';
import { AddObjectLinkComponent } from './components/add-object-link/add-object-link.component';
import { OrdinalNumberPipe } from './pipes/ordinal-number.pipe';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { TimeframeShortNamePipe } from './pipes/timeframe-short-name.pipe';
import { BulkUpdateDialogComponent } from './components/bulk-update-dialog/bulk-update-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AutocompleteControlComponent } from './components/autocomplete-control/autocomplete-control.component';
import { A11yModule } from '@angular/cdk/a11y';
import { LoaderComponent } from './components/loader/loader.component';
import { IconPreparingCampaignComponent } from './components/icons/icon-preparing-campaign/icon-preparing-campaign.component';
import { IconUnderwayCampaignComponent } from './components/icons/icon-underway-campaign/icon-underway-campaign.component';
import { IconLaunchedCampaignComponent } from './components/icons/icon-launched-campaign/icon-launched-campaign.component';
import { IconConcludedCampaignComponent } from './components/icons/icon-concluded-campaign/icon-concluded-campaign.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SortButtonComponent } from './components/sort-button/sort-button.component';
import { FederatedLoginSetupDialogComponent } from './modals/federated-login-setup-dialog/federated-login-setup-dialog.component';
import { FormattedBudgetValueComponent } from './components/formatted-budget-value/formatted-budget-value.component';
import { IconSubgroupArrowComponent } from './components/icons/icon-subgroup-arrow/icon-subgroup-arrow.component';
import { FlatSelectComponent } from './components/flat-select/flat-select.component';
import { TrimValidator } from './directives/trim-validator.directive';
import { MenuPanelComponent } from 'app/header-navigation/components/menu-panel/menu-panel.component';
import { MatMenuModule } from '@angular/material/menu';
import { PlDroppableDirective } from './directives/pl-droppable.directive';
import { PlDraggableDirective } from './directives/pl-draggable.directive';
import { StringTruncateDirective } from './directives/string-truncate';
import { MultiTitleDirective } from './directives/multi-title.directive';
import { PlusTogglerComponent } from './components/plus-toggler/plus-toggler.component';
import { MatRippleModule } from '@angular/material/core';
import { EqualValidator } from './directives/equal-validator.directive';
import { FileValueAccessor } from './directives/file-control-value-accessor';
import { FileValidator } from './directives/file-input.validator';
import { GenerateMetricsGraph } from './directives/generate-metrics-graph.directive';
import { AlreadyLoggedInGuard } from './guards/already-logged-in.guard';
import { AuthRequiredGuard } from './guards/auth-required.guard';
import { LeavePageGuard } from './guards/leave-page.guard';
import { SSOGuard } from './guards/sso.guard';
import { UserRolesGuard } from './guards/user-roles.guard';
import { GlCodeDialogComponent } from './modals/gl_code/gl-code-dialog.component';
import { IsWarningCellPipe } from './pipes/is-warning-cell.pipe';
import { IsoDatePipe } from './pipes/iso_date_filter';
import { SharedCostStateValuePipe } from './pipes/shared-cost-state-value.pipe';
import { BusinessValueTableComponent } from './components/business-value-table.component.ts/business-value-table.component';
import { IsScrolledDirective } from './directives/is-scrolled.directive';
import { IconGoogleAdsComponent } from './components/icons/icon-google-ads/icon-google-ads.component';
import { ScrollShadowDirective } from './directives/scroll-shadow.directive';
import { ParentHierarchySelectComponent } from './components/parent-hierarchy-select/parent-hierarchy-select.component';
import { HierarchySelectComponent } from './components/hierarchy-select/hierarchy-select.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { HierarchyMenuComponent } from './components/hierarchy-menu/hierarchy-menu.component';
import { CostRulesComponent } from './components/cost-rules/cost-rules.component';
import { NavigationComponent } from '../../super-admin-app/shared/navigation/navigation.component';
import { ProfileMenuComponent } from '../header-navigation/components/profile-menu/profile-menu.component';
import { MatRadioModule } from '@angular/material/radio';
import { DisableSsoDialogComponent } from './modals/disable-sso-dialog/disable-sso-dialog.component';
import { ArrowTogglerComponent } from './components/arrow-toggler/arrow-toggler.component';
import { IconFaDuotoneListTreeComponent } from './components/icons/icon-fa-duotone-list-tree/icon-fa-duotone-list-tree.component';
import { HierarchyOptionContentComponent } from './components/hierarchy-select/hierarchy-option-content/hierarchy-option-content.component';
import { HierarchyHeadComponent } from './components/hierarchy-select/hierarchy-head/hierarchy-head.component';
import { HierarchyOptionsListComponent } from './components/hierarchy-select/hierarchy-options-list/hierarchy-options-list.component';
import { ElementResizeObserverDirective } from './directives/element-resize-observer.directive';
import { ConfettiDirective } from './directives/confetti.directive';
import { IconLinkedinComponent } from './components/icons/icon-linkedin/icon-linkedin.component';
import { DragAndDropService } from './services/drag-and-drop.service';
import { DragImageDirective } from './directives/drag-image.directive';
import { TabSwitchComponent } from './components/tab-switch/tab-switch.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MetricPerformanceArrowComponent } from './components/metric-performance-arrow/metric-performance-arrow.component';
import { IconFaArrowRightComponent } from './components/icons/icon-fa-arrow-right/icon-fa-arrow-right.component';
import { IconFaArrowDownRightComponent } from './components/icons/icon-fa-arrow-down-right/icon-fa-arrow-down-right.component';
import { IconFaArrowUpRightComponent } from './components/icons/icon-fa-arrow-up-right/icon-fa-arrow-up-right.component';
import { MetricPerformanceTooltipDirective } from './directives/metric-performance-tooltip.directive';
import { MetricPerformanceTooltipComponent } from './components/metric-performance-tooltip/metric-performance-tooltip.component';
import { DynamicIconComponent } from './components/dynamic-icon/dynamic-icon.component';
import { FloatingTooltipDirective } from './directives/floating-tooltip.directive';
import { ExpenseTypeDialogComponent } from './modals/expense-type-dialog/expense-type-dialog.component';
import { IconGoogleLogoComponent } from './components/icons/icon-google-logo/icon-google-logo.component';
import { ScrollOnDragDirective } from './directives/scroll-on-drag.directive';
import { IconFacebookComponent } from './components/icons/icon-facebook/icon-facebook.component';
import { GoalsService } from './services/backend/goals.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TagMappingsPipe } from './pipes/tag-mappings.pipe';
import { TableContentShadowsDirective } from './directives/table-content-shadows.directive';
import { PrecisionNumberFormatPipe } from './pipes/precision-number-format.pipe';
import { NavigationToastComponent } from './components/navigation-toast/navigation-toast.component';
import { IconChevronsLeftComponent } from './components/icons/icon-chevrons-left/icon-chevrons-left.component';
import { SearchControlComponent } from './components/search-control/search-control.component';
import { PlcLibraryModule } from '@common-lib/lib/plc-library.module';
import { UniqueIdComponent } from './components/unique-id/unique-id.component';
import { MetricMastersListComponent } from '../budget-object-details/components/details-metrics/metric-masters-list/metric-masters-list.component';
import { SelectOptionComponent } from '../budget-object-details/components/details-metrics/select-option/select-option.component';
import { IconByObjectTypeComponent } from '../budget-object-details/components/icon-by-object-type/icon-by-object-type.component';
import { ObjectNameComponent } from '../budget-object-details/components/object-name/object-name.component';
import { BudgetSummaryBarComponent } from './components/budget-summary-bar/budget-summary-bar.component';
import { SummaryValueComponent } from './components/budget-summary-bar/summary-value/summary-value.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IconUserHelmetSafetyComponent } from './components/icons/icon-user-helmet-safety/icon-user-helmet-safety.component';
import { IconDotComponent } from './components/icons/icon-dot/icon-dot.component';
import { SharedCostRulesTooltipDirective } from './directives/shared-cost-rules-tooltip.directive';
import { SharedCostRulesTooltipComponent } from './components/shared-cost-rules-tooltip/shared-cost-rules-tooltip.component';
import { ExpenseStatusSelectComponent } from './components/expense-status-select/expense-status-select.component';
import { NewEntityLabelComponent } from './components/new-entity-label/new-entity-label.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { SummaryItemComponent } from './components/budget-summary-bar/summary-item/summary-item.component';
import { DynamicPortalTooltipDirective } from './directives/dynamic-portal-tooltip.directive';
import { DynamicPortalTooltipComponent } from '@shared/components/dynamic-portal-tooltip/dynamic-portal-tooltip.component';
import { LinkifiedTextComponent } from '@shared/components/linkified-text/linkified-text.component';
import { PdfDocumentViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ExpenseVerifiedIconComponent } from './components/expense-verified-icon/expense-verified-icon.component';
import { PdfViewerService } from './services/pdf-viewer.service';
import { HierarchySingleSelectComponent } from './components/hierarchy-select/hierarchy-single-select/hierarchy-single-select.component';
import { SpendingTimelineFormatPipe } from './pipes/spending-timeline-format.pipe';
import { CegStatusEnabledGuard } from '@shared/guards/ceg-status-enabled-guard.service';
import { SpendingBarComponent } from 'app/dashboard/spending-bar/spending-bar.component';
import { TimelineChartTooltipDirective } from 'app/dashboard/timeline-chart-tooltip.directive';
import { StatusesLegendComponent } from 'app/dashboard/statuses-legend/statuses-legend.component';
import { FullHierarchyTreeComponent } from '@shared/components/full-hierarchy-tree/full-hierarchy-tree.component';
import { AbsoluteNumberPipe } from '@shared/pipes/absolute-number.pipe';
import { TimeframeSelectComponent } from './components/timeframe-select/timeframe-select.component';
import { MetricPerformanceDataComponent } from './components/metric-performance-data/metric-performance-data.component';
import { AddObjectButtonComponent } from './components/add-object-button/add-object-button.component';
import { SsoFormComponent } from './components/sso-form/sso-form.component';
import { AuthExpiryModalComponent } from './modals/auth-expiry-modal/auth-expiry-modal.component';
import { IconAttributesComponent } from './components/icons/icon-attributes/icon-attributes.component';
import { GridWithSearchComponent } from './components/grid-with-search/grid.component';
import { EditableTextComponent } from './components/editable-text/editable-text.component';
import { ExpenseTypesTabComponent } from '../account/tab-expense-types/tab-expense-types.component';
import { TabGlCodesComponent } from 'app/account/tab-gl-codes/tab-gl-codes.component';
import { VendorsTabComponent } from '../account/tab-vendors/tab-vendors.component';
import { TagsTabComponent } from '../account/tab-tags/tab-tags.component';
import { GroupTypeTabComponent } from '../account/tab-campaign-expense-group-type/tab-campaign-expense-group-type.component';
import { GoalTypesTabComponent } from '../account/tab-goal-types/tab-goal-types.component';
import { GoalTypeService } from './services/backend/goal-type.service';
import { RoundedBoxRadioGroupComponent } from './components/rounded-box-radio-buttons/radio-button-group/radio-group.component';
import { RoundedBoxRadioItemComponent } from './components/rounded-box-radio-buttons/radio-button-item/radio-item.component';
import { BoxedCheckboxComponent } from './components/boxed-checkbox/boxed-checkbox.component';
import { DraggableOptionsComponent } from './components/draggable-options/draggable-options.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomFieldListComponent } from '../account/custom-fields/components/custom-field-list/custom-field-list.component';
import { CustomFieldGuard } from './guards/custom-field.guard';
import { ModalWithIconHeaderComponent } from './components/modal-with-icon-header/modal-with-icon-header.component';
import { BudgetSelectionComponent } from '../account/budget-selection-attributes-tags/budget-selection-attributes-tags.component';
import { EditCustomFieldsModalComponent } from './modals/edit-custom-fields-modal/edit-custom-fields-modal.component';
import { CustomFieldComponent } from '../budget-object-details/components/custom-fields/custom-field.component';

@NgModule({
  declarations: [
    SpendingBarComponent,
    SpendingTimelineComponent,
    TimelineChartTooltipDirective,
    StatusesLegendComponent,
    TimeframesComponent,
    IconCancelComponent,
    IconFlameComponent,
    IconTrashComponent,
    IconSortingComponent,
    IconAddObjectsComponent,
    IconLockComponent,
    IconSearchComponent,
    IconTagsComponent,
    IconGripComponent,
    IconPlannuhComponent,
    IconSalesforceComponent,
    IconGoogleAdsComponent,
    ExpandCollapseComponent,
    ActionsMenuComponent,
    TextInputComponent,
    AuditLogsComponent,
    ShowHideToggleComponent,
    DropdownMenuComponent,
    OptionsSelectComponent,
    CheckboxComponent,
    AutofocusDirective,
    DecimalInputDirective,
    CurrencyInputDirective,
    ScrollDetectorDirective,
    ModalWindowComponent,
    AutocompleteComponent,
    CurrencySelectComponent,
    TagsControlComponent,
    IconFavoriteComponent,
    IconTuneComponent,
    DropDownArrowComponent,
    IconSharedComponent,
    IconAttributesComponent,
    SegmentsRulesSelectComponent,
    SharedRuleTooltipComponent,
    TooltipContainerComponent,
    SimpleTooltipComponent,
    ScrollOverlapDirective,
    IconDownloadComponent,
    ConfirmationDialogComponent,
    BudgetStatusIconComponent,
    PlClickOutsideDirective,
    MaintenanceTooltipComponent,
    IconDeathStarComponent,
    IconMetricsComponent,
    IconMetricsDeleteComponent,
    IconMetricsDoneComponent,
    BarGraphComponent,
    SmallNumber,
    BudgetAmountsSummaryComponent,
    GetInitials,
    AddObjectLinkComponent,
    OrdinalNumberPipe,
    OrdinalDatePipe,
    TimeframeShortNamePipe,
    BulkUpdateDialogComponent,
    AutocompleteControlComponent,
    LoaderComponent,
    IconPreparingCampaignComponent,
    IconUnderwayCampaignComponent,
    IconLaunchedCampaignComponent,
    IconConcludedCampaignComponent,
    PageHeaderComponent,
    LinkButtonComponent,
    TruncatePipe,
    FederatedLoginSetupDialogComponent,
    SortButtonComponent,
    IconSubgroupArrowComponent,
    FlatSelectComponent,
    FormattedBudgetValueComponent,
    TrimValidator,
    MenuPanelComponent,
    PlDroppableDirective,
    PlDraggableDirective,
    StringTruncateDirective,
    MultiTitleDirective,
    PlusTogglerComponent,
    EqualValidator,
    FileValueAccessor,
    FileValidator,
    GenerateMetricsGraph,
    ExpenseTypeDialogComponent,
    GlCodeDialogComponent,
    IsWarningCellPipe,
    IsoDatePipe,
    SharedCostStateValuePipe,
    BusinessValueTableComponent,
    IsScrolledDirective,
    HierarchySelectComponent,
    ParentHierarchySelectComponent,
    ScrollShadowDirective,
    IsScrolledDirective,
    HierarchyMenuComponent,
    CostRulesComponent,
    NavigationComponent,
    ProfileMenuComponent,
    DisableSsoDialogComponent,
    ArrowTogglerComponent,
    IconFaDuotoneListTreeComponent,
    HierarchyOptionContentComponent,
    HierarchyHeadComponent,
    HierarchyOptionsListComponent,
    ElementResizeObserverDirective,
    IconLinkedinComponent,
    ConfettiDirective,
    DragImageDirective,
    TabSwitchComponent,
    MetricPerformanceArrowComponent,
    IconFaArrowRightComponent,
    IconFaArrowDownRightComponent,
    IconFaArrowUpRightComponent,
    MetricPerformanceTooltipDirective,
    MetricPerformanceTooltipComponent,
    FloatingTooltipDirective,
    DynamicIconComponent,
    IconFacebookComponent,
    IconGoogleLogoComponent,
    ScrollOnDragDirective,
    TagMappingsPipe,
    TableContentShadowsDirective,
    PrecisionNumberFormatPipe,
    NavigationToastComponent,
    IconChevronsLeftComponent,
    UniqueIdComponent,
    SearchControlComponent,
    MetricMastersListComponent,
    SelectOptionComponent,
    IconByObjectTypeComponent,
    ObjectNameComponent,
    BudgetSummaryBarComponent,
    SummaryValueComponent,
    IconUserHelmetSafetyComponent,
    IconDotComponent,
    SharedCostRulesTooltipDirective,
    SharedCostRulesTooltipComponent,
    ExpenseStatusSelectComponent,
    NewEntityLabelComponent,
    HighlightPipe,
    SummaryItemComponent,
    DynamicPortalTooltipComponent,
    DynamicPortalTooltipDirective,
    LinkifiedTextComponent,
    PdfDocumentViewerComponent,
    ExpenseVerifiedIconComponent,
    HierarchySingleSelectComponent,
    SpendingTimelineFormatPipe,
    FullHierarchyTreeComponent,
    AbsoluteNumberPipe,
    TimeframeSelectComponent,
    MetricPerformanceDataComponent,
    AddObjectButtonComponent,
    SsoFormComponent,
    AuthExpiryModalComponent,
    GridWithSearchComponent,
    EditableTextComponent,
    ExpenseTypesTabComponent,
    TabGlCodesComponent,
    VendorsTabComponent,
    TagsTabComponent,
    GroupTypeTabComponent,
    GoalTypesTabComponent,
    RoundedBoxRadioGroupComponent,
    RoundedBoxRadioItemComponent,
    BoxedCheckboxComponent,
    DraggableOptionsComponent,
    CustomFieldListComponent,
    ModalWithIconHeaderComponent,
    BudgetSelectionComponent,
    EditCustomFieldsModalComponent,
    CustomFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AngularMultiSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    A11yModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
    MatRippleModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    PlcLibraryModule,
    MatPaginatorModule,
    PdfViewerModule,
    ReactiveFormsModule,
    DragDropModule
  ],
  exports: [
    RouterModule,
    SpendingBarComponent,
    SpendingTimelineComponent,
    TimelineChartTooltipDirective,
    StatusesLegendComponent,
    TimeframesComponent,
    IconCancelComponent,
    IconTrashComponent,
    IconFlameComponent,
    IconSortingComponent,
    IconAddObjectsComponent,
    IconLockComponent,
    IconSearchComponent,
    IconTagsComponent,
    IconGripComponent,
    IconPlannuhComponent,
    IconSalesforceComponent,
    IconGoogleAdsComponent,
    ExpandCollapseComponent,
    ActionsMenuComponent,
    TextInputComponent,
    AuditLogsComponent,
    ShowHideToggleComponent,
    DropdownMenuComponent,
    OptionsSelectComponent,
    CheckboxComponent,
    AutofocusDirective,
    DecimalInputDirective,
    CurrencyInputDirective,
    AngularMultiSelectModule,
    ScrollDetectorDirective,
    ModalWindowComponent,
    ModalWindowComponent,
    AutocompleteComponent,
    CurrencySelectComponent,
    TagsControlComponent,
    IconFavoriteComponent,
    IconTuneComponent,
    DropDownArrowComponent,
    IconSharedComponent,
    IconAttributesComponent,
    SegmentsRulesSelectComponent,
    SharedRuleTooltipComponent,
    TooltipContainerComponent,
    SimpleTooltipComponent,
    IconDownloadComponent,
    BudgetStatusIconComponent,
    PlClickOutsideDirective,
    MaintenanceTooltipComponent,
    IconDeathStarComponent,
    IconMetricsComponent,
    IconMetricsDeleteComponent,
    IconMetricsDoneComponent,
    BarGraphComponent,
    SmallNumber,
    BudgetAmountsSummaryComponent,
    GetInitials,
    AddObjectLinkComponent,
    OrdinalDatePipe,
    OrdinalNumberPipe,
    TimeframeShortNamePipe,
    LoaderComponent,
    IconPreparingCampaignComponent,
    IconUnderwayCampaignComponent,
    IconLaunchedCampaignComponent,
    IconConcludedCampaignComponent,
    PageHeaderComponent,
    LinkButtonComponent,
    TruncatePipe,
    SortButtonComponent,
    FormattedBudgetValueComponent,
    ConfirmationDialogComponent,
    IconSubgroupArrowComponent,
    FlatSelectComponent,
    TrimValidator,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatChipsModule,
    MenuPanelComponent,
    PlDroppableDirective,
    PlDraggableDirective,
    StringTruncateDirective,
    MultiTitleDirective,
    PlusTogglerComponent,
    MultiTitleDirective,
    EqualValidator,
    FileValueAccessor,
    FileValidator,
    GenerateMetricsGraph,
    ExpenseTypeDialogComponent,
    GlCodeDialogComponent,
    IsWarningCellPipe,
    IsoDatePipe,
    SharedCostStateValuePipe,
    BusinessValueTableComponent,
    IsScrolledDirective,
    ScrollShadowDirective,
    HierarchySelectComponent,
    ParentHierarchySelectComponent,
    MatExpansionModule,
    HierarchyMenuComponent,
    CostRulesComponent,
    NavigationComponent,
    ProfileMenuComponent,
    MatInputModule,
    DisableSsoDialogComponent,
    ArrowTogglerComponent,
    IconFaDuotoneListTreeComponent,
    MetricPerformanceDataComponent,
    HierarchyHeadComponent,
    HierarchyOptionContentComponent,
    HierarchyOptionsListComponent,
    MatRippleModule,
    ElementResizeObserverDirective,
    IconLinkedinComponent,
    ConfettiDirective,
    DragImageDirective,
    TabSwitchComponent,
    MetricPerformanceArrowComponent,
    MetricPerformanceTooltipDirective,
    FloatingTooltipDirective,
    DynamicIconComponent,
    IconFacebookComponent,
    IconGoogleLogoComponent,
    ScrollOnDragDirective,
    MatSlideToggleModule,
    TagMappingsPipe,
    TableContentShadowsDirective,
    PrecisionNumberFormatPipe,
    NavigationToastComponent,
    IconChevronsLeftComponent,
    SearchControlComponent,
    PlcLibraryModule,
    UniqueIdComponent,
    MetricMastersListComponent,
    SelectOptionComponent,
    IconByObjectTypeComponent,
    ObjectNameComponent,
    BudgetSummaryBarComponent,
    SummaryValueComponent,
    MatPaginatorModule,
    IconUserHelmetSafetyComponent,
    IconDotComponent,
    SharedCostRulesTooltipDirective,
    SharedCostRulesTooltipComponent,
    ExpenseStatusSelectComponent,
    NewEntityLabelComponent,
    HighlightPipe,
    SummaryItemComponent,
    DynamicPortalTooltipComponent,
    DynamicPortalTooltipDirective,
    LinkifiedTextComponent,
    PdfDocumentViewerComponent,
    PdfViewerModule,
    ExpenseVerifiedIconComponent,
    MatTabsModule,
    HierarchySingleSelectComponent,
    SpendingTimelineFormatPipe,
    FullHierarchyTreeComponent,
    AbsoluteNumberPipe,
    TimeframeSelectComponent,
    AddObjectButtonComponent,
    SsoFormComponent,
    AuthExpiryModalComponent,
    GridWithSearchComponent,
    EditableTextComponent,
    ExpenseTypesTabComponent,
    TabGlCodesComponent,
    VendorsTabComponent,
    TagsTabComponent,
    GroupTypeTabComponent,
    GoalTypesTabComponent,
    RoundedBoxRadioGroupComponent,
    RoundedBoxRadioItemComponent,
    BoxedCheckboxComponent,
    DraggableOptionsComponent,
    CustomFieldListComponent,
    ModalWithIconHeaderComponent,
    BudgetSelectionComponent,
    EditCustomFieldsModalComponent,
    CustomFieldComponent
  ],
  providers: [
    SortDirectionsConstants,
    KeyCodesConstants,
    SmallNumber,
    TagMappingsPipe,
    OrdinalNumberPipe,
    OrdinalDatePipe,
    TimeframeShortNamePipe,
    IsoDatePipe,
    TruncatePipe,
    AlreadyLoggedInGuard,
    AuthRequiredGuard,
    LeavePageGuard,
    SSOGuard,
    UserRolesGuard,
    CustomFieldGuard,
    CegStatusEnabledGuard,
    DragAndDropService,
    GoalsService,
    PdfViewerService,
    HierarchySingleSelectComponent,
    GoalTypeService
  ]
})
export class SharedModule {}
