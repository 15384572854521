import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { IconHeaderModalService } from './modal-with-icon-header.service';
import { UtilityService } from './utility.service';
import { Configuration } from '../../app.constants';
import { LocalStorageService } from '../../../../../common-lib/src/services/local-storage.service';
import { ModalContext } from '../components/modal-with-icon-header/modal-with-icon-header.component';

@Injectable({
  providedIn: 'root',
})
export class EditBudgetModalService {
  private isBudgetEditModalOpen = false;

  constructor(
    private modalService: IconHeaderModalService,
    private utilityService: UtilityService,
    private configuration: Configuration
  ) {}

  handleOnFocus(event: boolean, budgetAmount: number, isForecastEnabled: boolean, ElementRef? : HTMLInputElement, showIntegrationArtefactManualChangeConfirmationHandler ?: (cb: () => void) => void): void {
    const budgetPopupKey = this.configuration.EDIT_TOTAL_BUDGET_POPUP_KEY_FOR_FORECAST;
    const dontShowBudgetEditPopup = LocalStorageService.getFromStorage(budgetPopupKey) || false;

    if (!dontShowBudgetEditPopup && event && budgetAmount > 0 && isForecastEnabled && !this.isBudgetEditModalOpen) {
      this.isBudgetEditModalOpen = true;
      let dontShowAgainChecked = false;

      const modalData: ModalContext = {
        title: 'Edit Total Budget',
        content: 'Consider editing the Forecast instead of the Total Budget to avoid losing your original budget amount.',
        icon: {
          prefix: 'fas',
          name: 'exclamation-triangle',
        },
        buttons: [{ text: 'Edit', color: 'primary', disabled: false }],
      };

      this.modalService
        .openInitialUpdatePopup(modalData, null, false, 'dropdownWithRightButton', false, true, (checked: boolean) => {
          dontShowAgainChecked = checked;
        })
        .pipe(
          finalize(() => {
            if(!showIntegrationArtefactManualChangeConfirmationHandler) {
              this.isBudgetEditModalOpen = false;
            }
            this.utilityService.showLoading(false);
          })
        )
        .subscribe((result) => {
          if (result) {
            if (dontShowAgainChecked) {
              LocalStorageService.addToStorage(budgetPopupKey, true);
            }
            if (ElementRef) {
              setTimeout(() => ElementRef.focus(), 0);
            }

            // Show the integration artefact manual change confirmation dialog
            if (showIntegrationArtefactManualChangeConfirmationHandler) {
              this.isBudgetEditModalOpen = true;
              showIntegrationArtefactManualChangeConfirmationHandler(() => {
                ElementRef.blur(); // Remove focus safely
                setTimeout(() => {
                  ElementRef.focus(); // Restore focus safely
                  this.isBudgetEditModalOpen = false; // Reset the flag after both dialogs are closed
                }, 0);
              });
            }

          } else {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement && typeof activeElement.blur === 'function') {
              activeElement.blur();
            }
            this.isBudgetEditModalOpen = false;
          }
        });
    }
  }
}
