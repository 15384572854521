<div class="rows action-block" *ngIf="data.viewMode === cegViewMode.Segments">
  <strong class="title gc-1">Rows</strong>
  <ng-container *ngFor="let config of data.config[tableViewSections.segmentBreakdownData]">
    <div class="action-item"
         *ngIf="config.visible"
         [ngClass]="config.type === tableActionType.checkbox ? 'gc-1' : 'gc-2'">
      <ng-container
        [ngTemplateOutlet]="config.type === tableActionType.checkbox ? actionCheckbox : actionToggle"
        [ngTemplateOutletContext]="{ $implicit: config, groupKey: tableViewSections.segmentBreakdownData }"
      ></ng-container>
    </div>
  </ng-container>
</div>
<div class="columns action-block">
  <strong class="title gc-1">Columns</strong>
  <ng-container *ngFor="let config of data.config[tableViewSections.columns]">
    <div class="action-item"
         *ngIf="config.visible"
         [ngClass]="[ isForecastEnabled ? 'forecast-enabled-config' : '' , config.type === tableActionType.checkbox ? 'gc-1' : 'gc-2']">
      <ng-container
        [ngTemplateOutlet]="config.type === tableActionType.checkbox ? actionCheckbox : actionToggle"
        [ngTemplateOutletContext]="{ $implicit: config, groupKey: tableViewSections.columns }"
      ></ng-container>
    </div>
  </ng-container>
</div>

<div class="action-wrapper">
  <button mat-button mat-dialog-close color="primary" (click)="data.onCancel()">
    Cancel
  </button>
  <button mat-flat-button mat-dialog-close color="primary" (click)="data.onSubmit(onConfigChangesApply())">
    Apply
  </button>
</div>

<ng-template #actionCheckbox let-configItem let-groupKey='groupKey'>
  <mat-checkbox
    color="primary"
    class="pl-mat-checkbox primary-ripple"
    [checked]="configItem.value"
    [disabled]="configItem.disabled"
    [disableRipple]="true"
    (change)="onSelectionChange(configItem, $event.checked, groupKey)"
  >
    {{ configItem.label }}
  </mat-checkbox>
</ng-template>

<ng-template #actionToggle let-configItem let-groupKey='groupKey'>
  <mat-slide-toggle
    [checked]="configItem.value"
    [disabled]="configItem.disabled"
    [ngClass]="{formula: configItem.key === tableConfigKeys.formula || configItem.key === tableConfigKeys.columnsFormula}"
    labelPosition="after"
    disableRipple="true"
    (change)="onSelectionChange(configItem, $event.checked, groupKey)"
    [matTooltip]="forecastCheckedTooltipMsgForFormula"
    [matTooltipDisabled]="configItem.key !== tableConfigKeys.columnsFormula || disableColumnFormulaTooltipForForecast"
    matTooltipClass="dark-theme-tooltip simple multi-line"
    matTooltipPosition="above"
  >
    {{ configItem.label }}
  </mat-slide-toggle>
</ng-template>
