import { Injectable } from '@angular/core';
import { concat, interval, merge, of, Subject, throwError } from 'rxjs';
import { delay, filter, map, retryWhen, switchMap, take, takeUntil } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { IntegrationStatus } from 'app/shared/enums/integration-statuses.enum';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { Budget } from 'app/shared/types/budget.interface';
import { Integration, SyncStatusResponse } from '../types/metrics-provider-data-service.types';
import { MetricIntegrationSyncStatusManager } from '../types/metric-integration-sync-status-manager.interface';
import { MetricIntegrationDisplayName, MetricIntegrationName } from '../types/metric-integration';
import { MetricIntegrations } from '../types/metric-integrations-status.interface';
import { MetricIntegrationsProviderService } from './metric-integrations-provider.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetObjectDetailsManager } from '../../budget-object-details/services/budget-object-details-manager.service';
import { HistoryObjectLogTypeNames } from '../../shared/types/history-object-log-type.type';
import { BudgetSegmentAccess } from '../../shared/types/segment.interface';

interface IntegrationData {
  metricIntegrationSyncStatusManager: MetricIntegrationSyncStatusManager;
  integrations: Integration[];
  integrationSource: MetricIntegrationName;
}

interface ExtendedIntegration extends Integration {
  metricIntegrationSyncStatusManager: MetricIntegrationSyncStatusManager;
  integrationSource: MetricIntegrationName;
}

const INFO_MESSAGE_DURATION = 5000;
const GET_SYNC_STATUS_INTERVAL_MS = 2000;
const GET_SYNC_STATUS_RETRIES_NUMBER = 3;
const GET_SYNC_STATUS_RETRY_DELAY_MS = 1000;
const INTEGRATION_NAME_PLACEHOLDER = '{INTEGRATION_NAME}';
const PROCESS_MESSAGE = {
  INITIAL: `All settings saved successfully! We are loading your ${INTEGRATION_NAME_PLACEHOLDER} data into Planful. Please don’t close or refresh your browser.`,
  COMPLETE: `Congratulations! Your ${INTEGRATION_NAME_PLACEHOLDER} integration is complete, and all of your ${INTEGRATION_NAME_PLACEHOLDER} campaigns are available in Planful.`,
  FAILED: 'Integration process failed.'
};

@Injectable({
  providedIn: 'root'
})
export class IntegrationSyncProgressService {
  private activeProcess = new Subject<Record<string, string>>();
  public activeProcess$ = this.activeProcess.asObservable();
  private reauthProcess = new Subject<boolean>();
  public reauthProcess$ = this.reauthProcess.asObservable();
  private readonly destroy$ = new Subject<void>();
  private readonly stopCurrentBudgetIntegrationsTracking$ = new Subject<void>();
  private readonly IntegrationStatus = IntegrationStatus;
  private runningIntegrations: Record<string, string> = {}; // integrationId: status
  private activeTrackingUnsubscribers: Record<string, Subject<void>> = {};
  private currentBudget: Budget;
  private currentBudgetIntegrations: Integration[] = [];

  constructor(
    private readonly configuration: Configuration,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly integrationProvidersService: MetricIntegrationsProviderService,
    private readonly utilityService: UtilityService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
  ) {}

  public init() {
    this.reset();

    concat(
      of(this.budgetDataService.selectedBudgetSnapshot),
      this.budgetDataService.selectedBudget$
    ).pipe(
      takeUntil(this.destroy$),
      filter(budget => budget != null && budget.id !== this.currentBudget?.id)
    ).subscribe(
      budget => {
        this.currentBudget = budget;
        this.cleanUpCurrentTrackingState();
        this.trackIntegrations(budget);
      }
    );
  }

  public reset(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.cleanUpCurrentTrackingState();
  }

  public reauthProcessCompleted(){
    this.reauthProcess.next(true);
  }

  private getIntegrationData(integrations: MetricIntegrations): IntegrationData[] {
    return [
      {
        metricIntegrationSyncStatusManager:
          this.integrationProvidersService.metricIntegrationProviderByType(MetricIntegrationName.GoogleAds),
        integrations: integrations?.[MetricIntegrationName.GoogleAds],
        integrationSource: MetricIntegrationName.GoogleAds
      },
      {
        metricIntegrationSyncStatusManager:
          this.integrationProvidersService.metricIntegrationProviderByType(MetricIntegrationName.LinkedinAds),
        integrations: integrations?.[MetricIntegrationName.LinkedinAds],
        integrationSource: MetricIntegrationName.LinkedinAds
      },
      {
        metricIntegrationSyncStatusManager:
          this.integrationProvidersService.metricIntegrationProviderByType(MetricIntegrationName.FacebookAds),
        integrations: integrations?.[MetricIntegrationName.FacebookAds],
        integrationSource: MetricIntegrationName.FacebookAds
      }
    ].filter(integrationData => integrationData.integrations?.length > 0);
  }

  private cleanUpCurrentTrackingState() {
    this.stopCurrentBudgetIntegrationsTracking$.next();
    this.activeTrackingUnsubscribers = {};
    this.activeProcess.next(
      this.runningIntegrations = {}
    );
    this.currentBudgetIntegrations = [];
  }

  public trackIntegrations(budget: Budget): void {
    this.companyDataService.metricIntegrations$
      .pipe(
        takeUntil(
          merge(this.stopCurrentBudgetIntegrationsTracking$, this.destroy$)
        ),
        map(integrations => this.getIntegrationData(integrations)),
        filter(integrationData => integrationData.length > 0),
        map(
          integrationData => {
            const newIntegrations = this.getNewIntegrations(integrationData);
            this.currentBudgetIntegrations = integrationData.flatMap(integrationGroup => integrationGroup.integrations);
            return newIntegrations;
          }
        )
      )
      .subscribe(
        gaIntegrations =>
          (gaIntegrations || []).forEach(
            item => this.trackIntegration(
              item.metricIntegrationSyncStatusManager,
              budget.company,
              item.integrationSource,
              item.integrationId
            )
          )
      );
  }

  public trackIntegration(
    metricIntegrationSyncStatusManager: MetricIntegrationSyncStatusManager,
    companyId: number,
    integrationSource: MetricIntegrationName,
    integrationId: string,
    removePrevStatus = false
  ): void {
    if (integrationId in this.runningIntegrations) { // Already tracking
      return;
    }

    this.runningIntegrations[integrationId] = null; // Waiting for status
    const unsubscriber = this.activeTrackingUnsubscribers[integrationId] = new Subject<void>();
    this.activeProcess.next(this.runningIntegrations);

    const getSyncStatus$ =
      interval(GET_SYNC_STATUS_INTERVAL_MS)
      .pipe(
        switchMap(() =>
          metricIntegrationSyncStatusManager.getDataSyncProgressStatus(companyId, integrationId)
        ),
        takeUntil(
          merge(this.stopCurrentBudgetIntegrationsTracking$, unsubscriber, this.destroy$)
        ),
        retryWhen(errors => concat(
          errors.pipe(
            delay(GET_SYNC_STATUS_RETRY_DELAY_MS),
            take(GET_SYNC_STATUS_RETRIES_NUMBER)
          ),
          throwError(new Error('Retry failed'))
        ))
      );

    (removePrevStatus ? metricIntegrationSyncStatusManager.deleteDataSyncProgressStatus(companyId, integrationId) : of(null))
      .pipe(switchMap(() => getSyncStatus$))
      .subscribe(
        response => this.responseHandler(integrationSource, response),
        () => this.stopTrackingIntegration(integrationId)
      );
  }

  private getNewIntegrations(data: IntegrationData[]): ExtendedIntegration[] {
    return data.flatMap(
      integrationGroup =>
        integrationGroup.integrations
          .filter(
            integration => !this.currentBudgetIntegrations.find(
              currentIntegration => currentIntegration.integrationId === integration.integrationId
            )
          )
          .map(
            integration => ({
              ...integration,
              integrationSource: integrationGroup.integrationSource,
              metricIntegrationSyncStatusManager: integrationGroup.metricIntegrationSyncStatusManager
            })
          )
    );
  }

  private responseHandler(integrationSource: MetricIntegrationName, response: SyncStatusResponse) {
    if (response.status === this.IntegrationStatus[response.integrationId]) {
      return;
    }

    const prevStatus = this.runningIntegrations[response.integrationId];
    this.runningIntegrations[response.integrationId] = response.status;

    switch (response.status) {
      case this.IntegrationStatus.RUNNING:
        this.activeProcess.next(this.runningIntegrations);
        if (!prevStatus) {
          this.utilityService.forceHideLoading();
          this.showProcessMessage(
            PROCESS_MESSAGE.INITIAL.replaceAll(INTEGRATION_NAME_PLACEHOLDER, MetricIntegrationDisplayName[integrationSource])
          );
        }
        break;

      case this.IntegrationStatus.SUCCEEDED:
        if (prevStatus === this.IntegrationStatus.RUNNING) {
          this.showProcessMessage(
            PROCESS_MESSAGE.COMPLETE.replaceAll(INTEGRATION_NAME_PLACEHOLDER, MetricIntegrationDisplayName[integrationSource])
          );
          this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
            this.currentBudget.id,
            HistoryObjectLogTypeNames.campaign
          );
        }
        this.stopTrackingIntegration(response.integrationId);
        break;

      case this.IntegrationStatus.FAILED:
        if (prevStatus === this.IntegrationStatus.RUNNING) {
          this.showProcessMessage(PROCESS_MESSAGE.FAILED);
        }
        this.stopTrackingIntegration(response.integrationId);
        break;

      default:
        this.stopTrackingIntegration(response.integrationId);
        break;
    }
  }

  public stopTrackingIntegration(integrationId: string): void {
    if (integrationId in this.runningIntegrations) {
      delete this.runningIntegrations[integrationId];
      this.activeProcess.next(this.runningIntegrations);
    }

    const unsubscriber = this.activeTrackingUnsubscribers[integrationId];
    if (unsubscriber) {
      delete this.activeTrackingUnsubscribers[integrationId];
      unsubscriber.next();
      unsubscriber.complete();
    }
  }

  private showProcessMessage(message: string): void {
    this.utilityService.showCustomToastr(message);
  }
}
