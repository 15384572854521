<div class="campaign-details" [formGroup]="formData">
  <details-timestamps
    [created]="currentState?.created"
    [updated]="currentState?.updated"></details-timestamps>

  <div class="details-panel">
    <!--Parent-->
    <div class="details-form-field w-50 parent">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'sitemap']" class="size-17"></fa-icon>
      </div>
      <parent-hierarchy-select
        [items]="locationItems"
        [disabled]="isReadOnlyMode"
        [showTriggerIcon]="true"
        [disableOptionCentering]="false"
        [selectedValue]="formData.get('location')?.value"
        [config]="{ selectPanelClass: 'details-parent-select', fieldAppearance: 'outline' }"
        (onChange)="handleParentSelectionChange.emit($event)"
      >
      </parent-hierarchy-select>
    </div>
    <!--Segment-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['far', 'chart-pie-simple']"></fa-icon>
      </div>
      <hierarchy-select
        [items]="allowedSegmentSelectItems"
        [disabled]="isReadOnlyMode"
        [selectControlExternal]="segmentControl"
        [config]="selectSegmentsConfig"
        [placeholder]="'Segment *'"
        [groupSelectionAllowed]="false"
        (onChange)="handleSegmentChanged.emit($event)"
      ></hierarchy-select>
    </div>
    <!-- Budget -->
    <div class="details-form-field w-50 planned-field">
      <mat-select class="currency-select"
                  formControlName="currencyCode"
                  (valueChange)="objectCurrencyChanged.emit($event)">
        <mat-option *ngFor="let item of currencyList" [value]="item.currency">
          {{ item.currency }}
        </mat-option>
      </mat-select>

      <div class="icon-container">
        <fa-icon [icon]="['fas', 'sack-dollar']"></fa-icon>
      </div>
      <mat-form-field *ngIf="currentState" appearance="outline" floatLabel="always" class="p-form-field-outline">
        <mat-label>Budget</mat-label>
        <input matInput
               [(ngModel)]="currentState.sourceAmount"
               [ngModelOptions]="{ standalone: true }"
               readonly
               class="text-left clickable-input"
               currencyMask
               [options]="currencyMaskOptions"
               (click)="onAmountClick.emit()"/>

        <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode" align="end">
          <span class="mr-1">{{ companyCurrencyCode }}:</span>
          <span>{{ currentState?.amount | formatBudget }}</span>
        </mat-hint>
      </mat-form-field>
    </div>
    
    <!-- Forecast -->
    <div 
    *ngIf="isForecastEnabled"
    class="details-form-field w-50 planned-field forecast-budget-cell">
      <mat-select 
        class="currency-select"
        [class.mat-select-disabled]="!currentState?.objectId"
        formControlName="currencyCode"
        [value]="currentState?.currencyCode"
        (valueChange)="objectCurrencyChanged.emit($event)"
        disabled="true">
        <mat-option *ngFor="let item of currencyList" [value]="item.currency">
          {{ item.currency }}
        </mat-option>
      </mat-select>

      <div class="icon-container">
        <span class="forecast-icon"><img src ="../../../../../assets/images/icon-forecast-transparent.svg" alt="Forecast"></span>
      </div>
      <mat-form-field *ngIf="currentState" appearance="outline" floatLabel="always" class="p-form-field-outline">
        <mat-label>Forecast</mat-label>
        <input matInput
               [(ngModel)]="currentState.sourceForecastAmount"
               [ngModelOptions]="{ standalone: true }"
               readonly
               class="text-left clickable-input"
               style="color: #B7C1D0"
               currencyMask
               [options]="currencyMaskOptions"
               (click)="onAmountClick.emit()"/>

        <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode" align="end">
          <span class="mr-1">{{ companyCurrencyCode }}:</span>
          <span>{{ currentState?.forecastAmount | formatBudget }}</span>
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- Status -->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'wave-pulse']" class="size-17"></fa-icon>
      </div>
      <amount-status-switch
        [isReadOnlyMode]="isReadOnlyMode"
        [parentCampaignIsCommitted]="parentCampaignIsCommitted"
        [statusFormControl]="formData.controls[DrawerFormFields.amountStatus]"
        (onChangeAmountState)="onChangeAmountState($event)"
      ></amount-status-switch>
    </div>
  </div>

  <div class="details-panel mb-2">
    <!--Object Type-->
    <div class="details-form-field w-50 ">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'rocket-launch']"></fa-icon>
      </div>

      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Expense Group Type'"
        [isRequired]="true"
        [formData]="formData"
        [optionTitle]="['name']"
        [control]="'typeId'"
        [addNullOption]="false"
        [value]="formData.controls['typeId'].value"
        [itemList]="objectTypes"
        [id]="'campType'"
      ></disabled-flag-mat-select>

    </div>
    <!--Owner-->
    <div class="details-form-field w-50 ">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-crown']"></fa-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Owner</mat-label>
        <mat-select
          [placeholder]="'Owner *'"
          formControlName="ownerId"
          required
          (selectionChange)="handleOwnerChange.emit($event)"
          [panelClass]="'reset-panel-width'"
        >
          <mat-option *ngFor="let item of ownerOptions" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formData.controls['ownerId'].hasError('required')">
          Owner is required
        </mat-error>
      </mat-form-field>
    </div>
    <!--GL Code-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'barcode-alt']" class="size-17"></fa-icon>
      </div>
      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'GL Code'"
        [isRequired]="false"
        [formData]="formData"
        [optionTitle]="['name', 'description']"
        [control]="'glCode'"
        [addNullOption]="true"
        [nullOptionTitle]="'No GL Code'"
        [value]="formData.controls['glCode'].value"
        [itemList]="glCodes"
      ></disabled-flag-mat-select>
    </div>
    <!-- PO number -->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>PO #</mat-label>
        <input matInput
               formControlName="poNumber"
               (change)="PONumberChanged.emit()"
               type="text"
               blurOnEnterKeydown
               maxlength="500"
               trim>
        <mat-error *ngIf="formData.controls['poNumber'].hasError('maxlength')">
          Po Number should be less 500 symbols
        </mat-error>
      </mat-form-field>
    </div>

    <!--Vendor Name-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-hard-hat']" class="size-22"></fa-icon>
      </div>
      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Vendor Name'"
        [isRequired]="false"
        [formData]="formData"
        [optionTitle]="['name']"
        [control]="'vendorId'"
        [addNullOption]="true"
        [nullOptionTitle]="'No Vendor'"
        [value]="formData.controls['vendorId'].value"
        [itemList]="vendors"
      ></disabled-flag-mat-select>
    </div>
    <!--Source-->
    <drawer-read-only-input
      class="details-form-field w-50"
      inputTitle="Source"
      iconRotation="90"
      icon="plug"
      iconType="fas"
      [objectSource]="currentState?.source"></drawer-read-only-input>

    <!--Campaign ID-->
    <div class="details-form-field w-50 uniq-id mb-1">
      <unique-id
        *ngIf="currentState?.externalId"
        [uniqueId]="currentState.externalId"
        [objectType]="objectType">
      </unique-id>
    </div>
  </div>

    <!-- Custom Fields -->
      <!-- TODO: remove the objectId non-null check from CF after Create API handles this -->
    <div class="details-panel custom-fields-container" *ngIf="isCustomFieldsEnabledForProgram && customFieldConfigs.length">
      <h2 class="panel-floating-title">Custom Fields</h2>
      <div [ngClass]="{'disabled-cf': isReadOnlyMode }" class="custom-field-el" *ngFor="let config of customFieldConfigs; let i = index">
        <custom-field-el
          [config]="config"
          [control]="form.controls['dropdown_' + i]" 
          (stateChanged)="onDropdownStateChanged($event, i)"
          (selectDropdownClosed)="onSelectDropdownClosed($event, 'dropdown_' + i)"
          >
        </custom-field-el>
      </div>
    </div>
  

  <div class="details-panel">
    <!--Tags-->
    <div class="details-form-field w-100">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'tags']" class="size-17"></fa-icon>
      </div>
      <tags-control
        #tagsControl
        class="mb-1"
        fieldAppearance="outline"
        [tagsList]="currentState?.tagMappings"
        [autocompleteItems]="tagsAutocompleteItems"
        [editOnly]="true"
        [editForbidden]="isReadOnlyMode"
        (onAddTag)="addTag($event)"
        (onCreateTag)="createTag($event)"
        (onRemoveTag)="removeTag($event)"
      >
      </tags-control>
    </div>
    <!--Attachments-->
    <div class="details-form-field attachments w-100 mb-1">
      <div class="icon-container">
        <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
      </div>
      <attachments-list
        [disableInput]="!currentState?.objectId"
        [objectTypeName]="objectType"
        [isReadOnlyMode]="isReadOnlyMode"
        [showAddIcon]="false"
        [attachments]="attachmentsManager.attachments"
        (onAttachFiles)="handleFileAttached.emit($event)"
        (onDeleteAttachment)="handleFileDelete.emit($event)"
        (onDownloadAttachment)="handleFileDownload.emit($event)"
      ></attachments-list>
    </div>
    <!--Notes-->
    <div class="details-form-field w-100 mb-1">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
      </div>
      <linkified-text formControlName="notes" label="Notes" appearance="outline"></linkified-text>
    </div>
  </div>

  <div class="details-panel">
    <h2 class="panel-floating-title">Tasks</h2>
    <!-- isReadOnlyMode -->
    <tasks-list
      [owners]="ownerOptions"
      [currentCompanyUser]="currentCompanyUser"
      [tasks]="currentState?.tasks"
      [budgetTodayDate]="budgetTodayDate"
      [isReadOnlyMode]="isReadOnlyMode"
      [isDrawer]="true"
      (onDataChanged)="handleTasksUpdate.emit($event)"
      #tasksList
    ></tasks-list>
  </div>
</div>
