import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Configuration } from 'app/app.constants';
import { debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetObjectCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { BudgetObjectDetailsComponent } from '../../../types/budget-object-details-component.interface';
import { ProgramDetailsForm, ProgramDetailsService } from '../../../services/program-details.service';
import { ObjectDetailsCommonState, ProgramDetailsState } from '../../../types/budget-object-details-state.interface';
import { BudgetObjectDetailsManager } from '../../../services/budget-object-details-manager.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyDataService, GLCode } from 'app/shared/services/company-data.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { DetailsAction } from '../../details-header/details-header.type';
import { CampaignTypeDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { LightProgram, ProgramDO, ProgramTypeDO } from 'app/shared/types/program.interface';
import { DetailsExpensesData, DetailsExpensesTotalRow } from '../../details-expenses/details-expenses-table/details-expenses-table.type';
import { BudgetObjectTagsService } from '../../../services/budget-object-tags.service';
import { TagControlEvent, TagsControlComponent } from 'app/shared/components/tags-control/tags-control.component';
import { BudgetAllocationsTableEvent } from '../../budget-allocations-table/budget-allocations-table.type';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { messageGetters, messages, objectPlaceholderName } from '../../../messages';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { Attachment } from 'app/shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from '../../../services/budget-object-attachments.service';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { LocationService } from '../../../services/location.service';
import { TaskListChangeEvent } from '../../tasks-list/tasks-list.component';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { BudgetObjectTasksService } from '../../../services/budget-object-tasks.service';
import { SegmentDataInheritanceAction } from 'app/shared/types/segment-data-inheritance.interface';
import { SegmentDataInheritanceService } from 'app/shared/services/segment-data-inheritance.service';
import { Tag } from 'app/shared/types/tag.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { BudgetAllocationsTableComponent } from '../../budget-allocations-table/budget-allocations-table.component';
import { HierarchySelectConfig, HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { getParentFromLocation } from 'app/shared/utils/location.utils';
import { DetailsExpensesService } from '../../details-expenses/details-expenses.service';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { ExtendedUserDO } from 'app/shared/types/user-do.interface';
import {
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../../services/budget-object-allocation.service';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from 'app/shared/constants/storage.constants';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { SegmentMenuHierarchyService } from 'app/shared/services/segment-menu-hierarchy.service';
import { SelectOption } from 'app/shared/types/select-option.interface';
import { BudgetObjectOwnersService } from '../../../services/budget-object-owners.service';
import { ExpenseCostAdjustmentDataService } from '../../../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { BudgetObjectEventType, BudgetObject, BudgetObjectEventContext } from '../../../types/budget-object-event.interface';
import { BudgetObjectActionsShared } from '../../../services/budget-object-actions-shared';
import { ExpenseDO } from '@shared/types/expense.interface';


@Component({
  selector: 'program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['../details-container.scss', './program-details.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectTagsService,
    BudgetObjectAttachmentsService,
    BudgetAllocationActionsService,
    ExpenseCostAdjustmentDataService,
  ]
})
export class ProgramDetailsComponent implements OnInit, OnDestroy, BudgetObjectDetailsComponent {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);

  @ViewChild('allocationsTable') allocationsTable: BudgetAllocationsTableComponent;
  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  public programFormData: UntypedFormGroup;
  private prevState: ProgramDetailsState = null;
  public currentState: ProgramDetailsState;

  private isPowerUser = false;
  public currentCompanyUser: CompanyUserDO;
  public companyId: number;
  public budget: Budget;
  public companyUsers: ExtendedUserDO[] = [];
  public programTypes: BudgetObjectType[] = [];
  public company: CompanyDO;

  public budgets: Budget[] = [];
  public budgetTimeframes: BudgetTimeframe[] = [];
  public goals: Goal[] = [];
  public campaigns: LightCampaign[] = [];
  public programs: LightProgram[] = [];
  public segments: BudgetSegmentAccess[];
  public segmentGroups: SegmentGroup[];
  public sharedCostRules: SharedCostRule[] = [];
  public allowedSharedCostRules: SharedCostRule[];
  public glCodes: GLCode[] = [];

  public readonly objectType = this.configuration.OBJECT_TYPES.program;
  public readonly ObjectMode = ObjectMode;
  public readonly displayObjectType = 'Expense Group';
  public companyCurrency: {
    symbol: string;
    code: string;
  };
  public menuActions: DetailsAction[] = [];
  public isLoading = true;
  public isReadOnlyMode = true;
  public isCustomTypeEntering = false;
  public editPermission = false;
  public unsavedChangesFlag = false;
  public locationItems: HierarchySelectItem[] = [];
  public ownerOptions: SelectOption[] = [];
  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null
  };
  public detailsExpensesTotals: DetailsExpensesTotalRow;
  public detailsExpensesData: DetailsExpensesData;
  public hasExternalIntegrationType = false;
  public segmentSelectItems: HierarchySelectItem[] = [];
  public allowedSegmentSelectItems: HierarchySelectItem[] = [];
  public selectSegmentsConfig: HierarchySelectConfig = {
    fieldLabel: 'Segment *',
    withSearch: true,
    emptyValueLabel: '',
    searchPlaceholder: 'Search Segments',
    allGroups: false,
    allPlural: 'Segments',
    errorMsg: 'Segment is required',
  };
  protected budgetTodayDate: Date;

  private isDeleteActionObject = { value: false };
  private selectedExpenses: ExpenseDO[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly configuration: Configuration,
    public readonly programDetailsService: ProgramDetailsService,
    public readonly appRoutingService: AppRoutingService,
    public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly userManager: UserManager,
    private readonly userDataService: UserDataService,
    private readonly utilityService: UtilityService,
    private readonly fb: UntypedFormBuilder,
    private readonly companyDataService: CompanyDataService,
    public readonly tagsManager: BudgetObjectTagsService,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    public readonly dataValidation: DataValidationService,
    private readonly budgetDataService: BudgetDataService,
    public readonly attachmentsManager: BudgetObjectAttachmentsService,
    public readonly hierarchyService: ObjectHierarchyService,
    private readonly locationService: LocationService,
    private readonly tasksManager: BudgetObjectTasksService,
    private readonly segmentDataInheritanceService: SegmentDataInheritanceService,
    private readonly detailsExpensesService: DetailsExpensesService,
    private readonly budgetObjectAllocationService: BudgetObjectAllocationService,
    public readonly gesturesManager: BudgetAllocationActionsService<any>,
    private readonly segmentMenuService: SegmentMenuHierarchyService,
    private readonly ownersService: BudgetObjectOwnersService,
    private readonly expenseCostAdjustmentDataService: ExpenseCostAdjustmentDataService
  ) {
    this._createForm();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.init(params));

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        filter(change => change.objectType === this.configuration.OBJECT_TYPES.expense),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateExpenses());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadInitialBudgetData() {
    return combineLatest([
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(map(budget => {
        this.budget = budget;
        this.budgetTodayDate = getTodayFixedDate(budget);
        return budget.id;
      }))]
    );
  }

  private syncUnsavedChangesFlag(flag?: boolean) {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  init(routeParams: Params) {
    this.reset$.next();
    if (this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)) {
      this.initProgramCreation();
    } else {
      const programIdParam = routeParams && routeParams[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
      const programId = programIdParam && Number.parseInt(programIdParam, 10);
      if (programId) {
        this.loadProgram(programId);
      } else {
        this.onError(
          '[Program Details]: init(): incorrect program id provided: ' + programIdParam,
          messages.UNABLE_TO_LOAD_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase()),
          true
        );
      }
    }

    this.userDataService.editPermission$
      .pipe(takeUntil(merge(this.reset$, this.destroy$)))
      .subscribe((editPermission: boolean) => {
        this.editPermission = editPermission;
        this.updateReadOnlyModeState();
      });
  }

  initProgramCreation(reset: boolean = false, contextData?: BudgetObjectCreationContext, afterInitCb: Function = null) {
    this.showLoader();
    if (!reset) {
      contextData = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');
    }
    const budgetData$ = reset ?
      of([this.companyId, this.budget.id]) :
      this.loadInitialBudgetData();

    budgetData$.pipe(
      mergeMap(([companyId, budgetId]) => (
        combineLatest([
          this.programDetailsService.initDetails(contextData, {
            company: companyId,
            budget: budgetId
          }),
          this.loadDetailsContextData(companyId)
        ])
      )),
      map(([objectDetailsState]) => {
        this.syncParentLists();
        this.initHierarchy(objectDetailsState);
        this.detectAddedParentObject(objectDetailsState);
        const fillAllocationsOptions = {
          suppressMode: this.budget.suppress_timeframe_allocations,
          CEGMode: false,
        };
        this.programDetailsService.preFillStateAllocations(objectDetailsState, this.budgetTimeframes, fillAllocationsOptions);
        if (contextData?.selectedExpenses) {
          this.selectedExpenses = contextData?.selectedExpenses;
          this.programDetailsService.fillAllocationsFromExpenses(
            objectDetailsState,
            this.budgetTimeframes,
            contextData.selectedExpenses
          );
        }
        this.programTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.programTypes);
        if (this.currentCompanyUser?.user) {
          objectDetailsState.createdBy = this.currentCompanyUser.user;
        }
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onProgramLoaded(state, false);
        if (afterInitCb) {
          afterInitCb();
        }
      },
      error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase()),
        true)
    );
  }

  private syncParentLists() {
    this.goals = this.budgetDataService.goalsSnapshot;
    this.campaigns = this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot;
  }

  private detectAddedParentObject(objectDetailsState) {
    merge(
      this.budgetDataService.goalList$.pipe(tap(goals => this.goals = goals)),
      merge(this.budgetDataService.lightCampaignList$, this.budgetDataService.campaignList$).pipe(
        tap(campaigns => this.campaigns = campaigns)
      )
    ).pipe(takeUntil(merge(this.destroy$, this.reset$)), take(1))
      .subscribe(() => this.applyParentObjects(objectDetailsState));
  }

  private applyParentObjects(objectDetailsState) {
    this.initHierarchy(objectDetailsState);
    this.updateLocationOptions();
  }

  private initHierarchy(objectDetailsState) {
    this.hierarchy = this.hierarchyService.buildObjectHierarchy(
      {
        id: objectDetailsState.objectId,
        name: objectDetailsState.name,
        goalId: objectDetailsState.goalId,
        campaignId: objectDetailsState.campaignId,
      },
      this.objectType,
      { goals: this.goals, campaigns: this.campaigns }
    );
  }

  loadProgram(programId: number) {
    this.showLoader();
    this.loadInitialBudgetData().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.programDetailsService.loadDetails(companyId, budgetId, programId, { isCEGMode: false }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      tap(([state]) => {
        this.budgetObjectDetailsManager.checkObjectBudgetStateConsistency({
          state,
          budgetId: this.budget?.id
        });
        this.budgetObjectDetailsManager.checkObjectAccess({
          segmentDO: {
            split_rule: state.segment && state.segment.sharedCostRuleId,
            company_budget_segment1: state.segment && state.segment.segmentId,
          },
          segments: this.segments,
          rules: this.sharedCostRules,
          onDenyCb: () => {
            this.destroy$.next();
            this.destroy$.complete();
          },
          objectTypeLabel: this.displayObjectType.toLowerCase()
        });
      }),
      map(([objectDetailsState]) => {
        this.hierarchy =
          this.hierarchyService.buildObjectHierarchy(
            {
              id: objectDetailsState.objectId,
              name: objectDetailsState.name,
              goalId: objectDetailsState.goalId,
              campaignId: objectDetailsState.campaignId,
            },
            this.objectType,
            {
              goals: this.goals,
              campaigns: this.campaigns
            }
          );
        this.defineParent(objectDetailsState);
        const fillAllocationsOptions = {
          suppressMode: false,
          CEGMode: false,
        };
        this.programDetailsService.preFillStateAllocations(objectDetailsState, this.budgetTimeframes, fillAllocationsOptions);
        this.programTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.programTypes, objectDetailsState.typeId);
        this.loadAttachments(objectDetailsState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onProgramLoaded(state);
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budget.id,
          this.currentCompanyUser.user,
          HistoryObjectLogTypeNames.program,
          this.programDetailsService);
      },
      error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase()),
        true
      )
    );
  }

  private resetDetails(contextData) {
    const afterResetCb = () => {
      if (this.allocationsTable) {
        this.allocationsTable.reset();
      }
    };
    this.reset$.next();
    this.programFormData.reset();
    this.prevState = null;
    this.currentState = null;
    this.initProgramCreation(true, contextData, afterResetCb);
  }

  defineParent(objectDetailsState: ProgramDetailsState) {
    objectDetailsState.parentObject = this.hierarchyService.getParentFromHierarchy(this.hierarchy, this.objectType);
  }

  defineCurrency() {
    this.companyCurrency = {
      symbol: this.company.currency_symbol,
      code: this.company.currency
    };
  }

  loadDetailsContextData(companyId: number) {
    const typesErrorCb = error => this.onError(
      error,
      messages.UNABLE_TO_LOAD_OBJECT_TYPES_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase()),
      true);
    if (this.budgetDataService.isCurrentBudgetWithNewCEGStructure) {
      this.companyDataService.loadObjectTypes(companyId, typesErrorCb);
    } else {
      this.companyDataService.loadProgramTypes(companyId, typesErrorCb);
    }

    this.companyDataService.loadTags(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_TAGS_ERROR_MSG, true));

    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company)),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.budgetObjectDetailsManager.getProgramTypes().pipe(tap(types => this.programTypes = [
          ...types,
          this.budgetObjectDetailsManager.enterCustomTypeOption
        ])),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.budgetObjectDetailsManager.getSegments().pipe(
          tap(segments => {
            this.segments = segments;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getSegmentGroups().pipe(
          tap(items => {
            this.segmentGroups = items;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
        this.budgetObjectDetailsManager.getAllowedSharedCostRules().pipe(
          tap(rules => {
            this.allowedSharedCostRules = rules;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.budgetObjectDetailsManager.getGlCodes().pipe(tap(glCodes => this.glCodes = glCodes)),
        this.userManager.currentCompanyUser$.pipe(
          filter(user => !!user),
          tap(user => {
            this.currentCompanyUser = user;
            this.isPowerUser = this.userDataService.isPowerUser(user);
          })
        )
      ]
    );
  }

  get isProgramOpen(): boolean {
    if (!this.currentState) {
      return false;
    }

    const { mode } = this.currentState;
    return mode === ObjectMode.Open || mode === ObjectMode.Planned;
  }

  get hasEditPermissions(): boolean {
    return this.editPermission;
  }

  hasUnsavedChanges(): boolean {
    if (!this.currentState || this.isDeleteActionObject.value) {
      return false;
    }
    // TODO: WE SHOULDN'T SAVE FORM DATA DURING CHECK UNSAVED CHANGES
    this.saveFormData();
    return this.programDetailsService.hasChanges(this.prevState, this.currentState);
  }

  private saveDetailsData$(): Observable<ProgramDO> {
    const isNewObject = !this.currentState.objectId;
    this.saveFormData();

    return this.programDetailsService.saveDetails(
      this.prevState,
      this.currentState,
      { suppressMode: this.budget.suppress_timeframe_allocations }
    ).pipe(
      tap((program: ProgramDO) => {
        this.currentState.spreadSegmentToChildren = false;
        this.currentState.spreadGLCodeToChildren = false;
        this.currentState.spreadPONumberToChildren = false;
        this.currentState.externalId = program.external_id;
        if (isNewObject) {
          this.programs.push(this.budgetDataService.convertProgram(program));
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, program.id);
        }
      })
    );
  }

  saveChanges(onSavedCb: Function = null): void {
    const isNewObject = !this.currentState.objectId;

    const save$ = allocationsCheckResult => this.budgetObjectActionsShared.saveContextData$(
      this.companyId,
      this.objectType,
      this.programFormData.get('typeId').value,
      this.programTypes,
      this.currentState.tagMappings,
      this.programFormData
    ).pipe(
      switchMap(() => this.saveDetailsData$()),
      switchMap((program: ProgramDO) => this.budgetObjectActionsShared.saveMappings(
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) => BudgetObjectActionsShared.saveTasks$(
        this.tasksManager,
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) => this.budgetObjectActionsShared.updateTagMappings$(
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) =>
        this.selectedExpenses?.length
          ? this.programDetailsService.updateSelectedExpensesParent(program.id, this.selectedExpenses)
          : of(program)
      ),
      switchMap(_ =>
        this.budgetObjectAllocationService.updateObjectRelatedAllocations$(
          allocationsCheckResult,
          this.prevState,
          this.currentState,
          this.budgetTimeframes,
          this.budget.suppress_timeframe_allocations
        )
      )
    );

    this.budgetObjectAllocationService.checkAllocationAmountForObjectDetails$(
      this.prevState,
      this.currentState,
      this.programs,
      this.campaigns,
      this.budget.suppress_timeframe_allocations,
      this.objectType
    ).pipe(
      switchMap((res: AllocationCheckResultData) => {
        if (res.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
          this.showLoader();
          return save$(res).pipe(map(_ => true));
        }
        return of(false);
      }),
      filter(saved => saved)
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(isNewObject);
        onSavedCb?.();
      },
      error: error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase())
      )
    });
  }

  updateReadOnlyModeState() {
    this.isReadOnlyMode = !this.hasEditPermissions || !this.isProgramOpen;
    this.updateMenuActions();
  }

  onProgramLoaded(state, syncState = true) {
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }
    this.resetFormData();
    this.applyExternalIntegrationRestrictions();
    this.updateDetailsExpensesTotals();
    this.updateReadOnlyModeState();
    this.defineCurrency();
    this.updateOwnerOptions(this.currentState.segment.segmentId, this.currentState.segment.sharedCostRuleId);
    this.defineAllowedSegments(this.currentState.ownerId);
    this.setFormData();
    this.updateLocationOptions();
    this.hideLoader();
    this.updateDetailsExpensesData();

    this.programFormData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.syncUnsavedChangesFlag());
  }

  validateChanges(): boolean {
    if (this.programFormData.valid) {
      return true;
    }

    this.dataValidation.validateFormFields(this.programFormData);
    return false;
  }


  submitChanges(submitCallback) {
    const isFormValid = this.validateChanges();
    if (isFormValid && submitCallback) {
      submitCallback();
    }
  }

  onSavedSuccessfully(isNewObject: boolean) {
    if(!this.prevState || this.prevState.name !== this.currentState.name) {
      this.reflectChangesInRecentlyAddedMenu()
    }
    if (!this.prevState) {
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.program,
        this.programDetailsService
      );
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.updateMenuActions();
    }
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject, this.displayObjectType);
    this.onSuccess(message);
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.triggerBudgetObjectEvent(
      this.currentState.objectId,
      isNewObject ? BudgetObjectEventType.Created : BudgetObjectEventType.Updated,
      this.prevState
    );
    this.syncUnsavedChangesFlag(false);
    this.setFormValidators(this.currentState.name);
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.program,
      this.currentState.name
    );
  }

  private showLoader() {
    this.isLoading = true;
    this.utilityService.showLoading(true);
  }

  private hideLoader() {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this.isLoading = false;
      this.utilityService.showLoading(false);
    });
  }

  /* TAGS */
  createTag(tag: TagControlEvent) {
    this.tagsManager.addLocalTag(tag);
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  addTag(tag: TagControlEvent) {
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  removeTag(tag: TagControlEvent) {
    this.tagsManager.deleteLocalTag(tag);
    this.tagsManager.deleteLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  /* ALLOCATIONS */
  handleAllocationsUpdate($event: BudgetAllocationsTableEvent) {
    const { allocationId, amount } = $event;
    const updatedAllocation = this.currentState.allocations.find(alloc => alloc.company_budget_alloc === allocationId);
    if (updatedAllocation) {
      updatedAllocation.source_amount = amount as number; // TODO: recalculate source_amount based on exchange rate (currency scope)
      updatedAllocation.amount = amount as number;
      this.updateLocationOptions();
      this.syncUnsavedChangesFlag();
    }
  }

  handleTotalAllocatedUpdate($event: number) {
    this.currentState.amount = $event;
    this.syncUnsavedChangesFlag();
  }

  private updateOwnerOptions(segmentId: number, sharedCostRuleId?: number) {
    this.ownerOptions = BudgetObjectOwnersService.getOwnerOptions(
      this.budget.id,
      segmentId,
      sharedCostRuleId,
      this.currentState.ownerId,
      this.companyUsers,
      this.sharedCostRules,
    );
  }

  private defineAllowedSegments(ownerId: number) {
    this.allowedSegmentSelectItems = BudgetObjectOwnersService.getAllowedSegmentOptions({
      ownerId,
      budgetId: this.budget.id,
      sharedCostRules: this.sharedCostRules,
      segmentSelectItems: this.segmentSelectItems,
      companyUsers: this.companyUsers
    }, this.configuration.OBJECT_TYPES);
  }

  /* ACTIONS */
  private updateMenuActions() {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addCloneAction(this.displayObjectType, this.handleClone.bind(this), this.isReadOnlyMode);

    if (this.isProgramOpen) {
      this.menuActionsBuilder.addCloseAction(this.displayObjectType, this.handleClose.bind(this), !this.hasEditPermissions);
    } else {
      this.menuActionsBuilder.addOpenAction(this.displayObjectType, this.handleOpen.bind(this), !this.hasEditPermissions);
    }
    const integrationExpenseTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(this.programTypes);
    const isBudgetActionDisabled = integrationExpenseTypeIds.includes(this.currentState.typeId) || this.isReadOnlyMode;

    this.menuActionsBuilder
      .addChangeBudgetAction(isBudgetActionDisabled)
      .addDeleteAction(this.displayObjectType, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  private onObjectModeUpdated(program: ProgramDO) {
    this.currentState.mode = program.mode;
    this.currentState.statusTotals = program.status_totals;
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    this.updateReadOnlyModeState();
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Updated, this.prevState);
    this.programDetailsService.reloadChildObjects(this.companyId, this.currentState)
      .subscribe(
        () => {
          this.updateDetailsExpensesData();
          this.updateDetailsExpensesTotals();
        }
      );
  }

  handleClone() {
    const clone$ = this.programDetailsService.cloneObject(this.currentState.objectId)
      .pipe(
        tap(() => this.budgetObjectDetailsManager.reportDetailsChange(this))
      );

    const checkAndClone$ =
      this.programDetailsService.getProgram(this.currentState.objectId, this.programs).pipe(
        switchMap(program =>
          this.budgetObjectAllocationService.checkAllocationAmountAndCloneSegmentedObject(
            program,
            this.objectType,
            program.campaignId,
            clone$,
            this.campaigns,
            this.programs,
            this.budget.suppress_timeframe_allocations
          )
        )
      );

    this.budgetObjectDetailsManager.cloneObject(checkAndClone$, this.displayObjectType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.triggerBudgetObjectEvent(data?.id, BudgetObjectEventType.Created);
          this.appRoutingService.openProgramDetails(data?.id);
        },
        error: err => this.onError(
          err,
          messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase())
        )
      });
  }

  handleOpen() {
    this.showLoader();
    this.programDetailsService.reopenObject(this.currentState.objectId)
      .subscribe(
        (program: ProgramDO) => this.onObjectModeUpdated(program),
        error => this.onError(
          error,
          messages.UNABLE_TO_REOPEN_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase())
        ),
        () => this.hideLoader()
      );
  }

  handleClose() {
    const closeSubmitHandler = () => {
      this.showLoader();
      this.programDetailsService.closeObject(this.currentState.objectId)
        .subscribe(
          (program: ProgramDO) => this.onObjectModeUpdated(program),
          error => this.onError(
            error,
            messages.UNABLE_TO_REOPEN_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase())
          ),
          () => this.hideLoader()
        );
    };

    this.dialogManager.openConfirmationDialog({
      title: messages.CLOSE_EXPENSE_GROUP_TITLE,
      content: messages.CLOSE_OBJECT_MSG,
      submitAction: {
        label: 'OK',
        handler: closeSubmitHandler
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    },
      {
        width: '480px'
      });
  }

  handleDelete() {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.budgetObjectDetailsManager.detachDirectChildObjects({
        expenses: this.currentState.expenses
      }, this.objectType.toLowerCase())
      .pipe(
        switchMap(() =>
          this.programDetailsService.deleteObject(this.currentState.objectId)
        ),
        tap(() => this.isDeleteActionObject.value = true),
        tap(() => this.expenseCostAdjustmentDataService.setIntegrationExpenses(this.programs, this.programTypes))
      ).subscribe({
        next: () => {
          this.reflectChangesInRecentlyAddedMenu();
          this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.displayObjectType), true);
          this.budgetObjectDetailsManager.reportDetailsChange(this);
          this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted);
          this.expenseCostAdjustmentDataService.disableExpenseCostAdjustment([this.currentState.objectId], this.companyId).subscribe();
        },
        error: error => this.onError(
          error,
          messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.displayObjectType.toLowerCase())
        )
      });
    }, this.displayObjectType.toLowerCase());
  }

  handleBudgetToMoveSelected(budget: Budget): void {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      false,
      () => {
        this.reflectChangesInRecentlyAddedMenu()
      }
    );
  }

  reflectChangesInRecentlyAddedMenu(): void {
    this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
      this.budget.id,
      HistoryObjectLogTypeNames.program
    );
  }

  handleCancelAction() {
    this.appRoutingService.closeDetailsPage();
  }

  handleSaveAction() {
    this.checkTagsLeftover();
    if (this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  handleSaveAndNewAction() {
    this.checkTagsLeftover();
    const contextData = this.getContextForNewObjectCreation();
    const onSaved = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)
      ? this.resetDetails.bind(this, contextData)
      : this.appRoutingService.openProgramCreation.bind(this.appRoutingService, contextData);

    if (this.hasUnsavedChanges()) {
      this.saveChanges(onSaved);
      return;
    }

    onSaved();
  }

  handleSaveAndCloseAction() {
    this.checkTagsLeftover();

    if (this.hasUnsavedChanges()) {
      const onSaved = this.appRoutingService.closeDetailsPage.bind(this.appRoutingService);
      this.saveChanges(onSaved);
      return;
    }
    this.appRoutingService.closeDetailsPage();
  }

  private checkTagsLeftover(): void {
    this.tagsManager.checkInputLeftover(this.tagsControl, this.currentState.tagMappings);
  }

  handleTypeChange() {
    const { typeId } = this.programFormData.value;
    this.isCustomTypeEntering = typeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
    this.resetUnsavedCustomTypes();
  }

  handleCustomTypeChange() {
    this.budgetObjectActionsShared.handleCustomTypeChange(this.programFormData, this.programTypes, this.companyId, this.prevState?.typeId);
    this.isCustomTypeEntering = false;
  }

  resetUnsavedCustomTypes() {
    this.programTypes = this.programTypes.filter(
      cType => !cType.isCustom || cType.id != this.budgetObjectDetailsManager.unsavedCustomTypeId
    );
    this.programFormData.patchValue({ customType: ''});
  }

  /* FORM DATA */
  private updateLocationOptions() {
    const currentLocationValue = this.programFormData.get('location').value;
    const { flatItemIdsList, items } = this.locationService.createLocationHierarchyItems({
      goals: this.goals,
      campaigns: this.campaigns,
      programs: [],
      currentLocation: currentLocationValue,
      segments: this.segments,
      rules: this.sharedCostRules,
      isPowerUser: this.isPowerUser
    });

    this.locationItems = items;
    // Reset current location if it's out of the list of locations now:
    if (currentLocationValue && !flatItemIdsList.includes(currentLocationValue)) {
      this.programFormData.get('location').setValue(null);
    }
  }

  updateDetailsExpensesTotals() {
    this.detailsExpensesTotals = this.detailsExpensesService.prepareTotalsRow(this.currentState.statusTotals);
  }

  private resetFormData() {
    const nameControl = this.programFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = null;
      nameControl.setErrors(null);
    }
    if (this.allocationsTable) {
      this.allocationsTable.reset();
    }
  }

  private setFormValidators(nameValue: string) {
    const nameControl = this.programFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = this.dataValidation.uniqueNameValidator(
        this.companyId, this.budget.id, nameValue, this.objectType
      );
    }
  }

  private setFormData() {
    const { name, segment, ownerId, typeId = null, glCode, poNumber, notes = '' } = this.currentState;
    const location = this.locationService.defineLocationValue(this.currentState.parentObject);
    const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem(segment, this.segmentSelectItems);
    const owner = ownerId || (this.currentCompanyUser?.user);
    const formData: ProgramDetailsForm = {
      name,
      segment: segmentedValue,
      location,
      ownerId: owner,
      typeId,
      customType: '',
      glCode,
      poNumber,
      notes
    };
    this.programFormData.setValue(formData);
    this.setFormValidators(name);
    this.performAutofill();
  }

  private performAutofill() {
    this.budgetObjectDetailsManager.autofillSegmentValue(
      this.segmentControl,
      this.segmentSelectItems
    );
    this.budgetObjectDetailsManager.autofillTypeSelectValue(
      this.programFormData.get('typeId'),
      this.programTypes.filter(type => !!type.id)
    );
  }

  private formDataToState(): Partial<ProgramDetailsState> {
    const formData = this.programFormData.getRawValue() as ProgramDetailsForm;
    const stateSegment = this.budgetObjectDetailsManager.hierarchyItemToState(formData.segment);
    const state: Partial<ProgramDetailsState> = {
      name: formData.name,
      notes: formData.notes,
      typeId: formData.typeId,
      ownerId: formData.ownerId,
      segment: stateSegment,
      glCode: formData.glCode,
      poNumber: formData.poNumber,
    };

    state.parentObject = getParentFromLocation(formData.location);
    if (state.parentObject) {
      state[state.parentObject.type.toLowerCase() + 'Id'] = state.parentObject.id;
    }
    return state;
  }

  private saveFormData() {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    });
  }

  private _createForm() {
    const formData = {
      name: ['', {
        validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
        updateOn: 'blur'
      }],
      segment: [null, this.dataValidation.segmentValidator()],
      ownerId: [null, Validators.required],
      location: null,
      typeId: [null, [Validators.required, Validators.min(0)]],
      customType: '',
      glCode: null,
      poNumber: ['', {
        validators: Validators.maxLength(500),
        updateOn: 'blur'
      }],
      notes: ''
    };
    this.programFormData = this.fb.group(formData);
  }

  get glCodeActiveName(): string {
    const glId = this.programFormData.controls['glCode'].value;
    const glItem = this.glCodes.find(code => code.id === glId);
    return glItem ? glItem.name : '';
  }

  private onSuccess(message: string, close = false) {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      this.appRoutingService.closeDetailsPage();
    }
  }

  private onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState as ObjectDetailsCommonState);
  }

  getContextForChildObjectCreation(): BudgetObjectCreationContext {
    return {
      parent: {
        id: this.currentState.objectId,
        type: this.objectType
      },
      glCodeId: this.currentState.glCode,
      poNumber: this.currentState.poNumber,
      segmentId: this.currentState.segment && this.currentState.segment.segmentId,
      sharedCostRuleId: this.currentState.segment && this.currentState.segment.sharedCostRuleId
    };
  }

  addNewExpense() {
    if (!this.currentState.objectId) {
      this.utilityService.showToast({ Message: messages.SAVING_BEFORE_ADD_EXPENSE.replace(objectPlaceholderName, this.displayObjectType) });
      this.submitChanges(
        () => this.saveChanges(
          () => this.appRoutingService.openExpenseCreation(this.getContextForChildObjectCreation())
        )
      );
    } else {
      this.appRoutingService.openExpenseCreation(this.getContextForChildObjectCreation());
    }
  }

  viewExpenses() {
    if (this.currentState.objectId != null) {
      this.budgetObjectDetailsManager.viewExpenses({
        [FilterName.ExpenseBuckets]: [this.currentState.objectId]
      });
    }
  }

  loadAttachments(state: ProgramDetailsState) {
    this.attachmentsManager.setObjectContext({
      objectId: state.objectId,
      objectType: this.objectType,
      companyId: this.companyId
    });
    this.attachmentsManager.loadAttachments(state.attachmentMappings)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  handleFileAttached($event) {
    this.attachmentsManager.uploadFiles($event.target.files)
      .subscribe({
        error: (err) => this.onError(err, ''),
        complete: () => $event.target.value = null
      });
  }

  handleFileDelete(attachment: Attachment) {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.attachmentsManager.deleteFile(attachment)
        .subscribe({
          error: (err) => this.onError(err, '')
        });
    }, 'file');
  }

  handleFileDownload(attachment: Attachment) {
    this.attachmentsManager.downloadFile(attachment)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  public handleParentSelectionChange(location: string) {
    const prevLocation = this.locationService.defineLocationValue(this.currentState.parentObject);

    this.programFormData.get('location').setValue(location);
    this.handleSegmentOnLocationChange(
      location,
      () => this.programFormData.patchValue({ location: prevLocation })
    );
  }

  private onChangeSegment(
    segmentId: number,
    sharedCostRuleId: number,
    action: SegmentDataInheritanceAction,
    prevSegment: HierarchySelectItem
  ): void {
    if (action === SegmentDataInheritanceAction.None) {
      this.programFormData.patchValue({ segment: prevSegment }, { emitEvent: false });
      this.syncUnsavedChangesFlag();
      return;
    }

    this.updateOwnerOptions(segmentId, sharedCostRuleId);
    BudgetObjectActionsShared.processSegmentChangeAction(
      this.currentState as ObjectDetailsCommonState,
      action,
      segmentId,
      sharedCostRuleId,
      false
    );
  }

  updateSegmentSelectItems() {
    if (!this.segments || !this.allowedSharedCostRules || !this.segmentGroups) {
      return;
    }
    this.segmentSelectItems = this.segmentMenuService.prepareDataForSegmentMenu({
      segments: this.segments,
      groups: this.segmentGroups,
      rules: this.allowedSharedCostRules,
    });
    this.allowedSegmentSelectItems = [...this.segmentSelectItems];
  }

  get segmentControl(): AbstractControl {
    return this.programFormData.get('segment');
  }

  handleSegmentChanged(event: HierarchySelectItem) {
    const { segmentId, sharedCostRuleId } = this.budgetObjectDetailsManager.hierarchyItemToState(event);
    const prevSegment = this.budgetObjectDetailsManager.segmentedValueToSelectItem(this.currentState.segment, this.segmentSelectItems);
    const hasChildren = this.currentState.expenses.length > 0;

    if (hasChildren) {
      this.segmentDataInheritanceService
        .confirmSegmentChange(this.objectType, hasChildren, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe(action => this.onChangeSegment(segmentId, sharedCostRuleId, action, prevSegment));
    } else {
      this.updateOwnerOptions(segmentId, sharedCostRuleId);
    }
  }

  handleGLCodeChanged(glCodeId: number) {
    this.spreadValueToChildExpenses(
      'GL Code',
      confirm => this.currentState.spreadGLCodeToChildren = confirm
    );
  }

  handlePONumberChanged(poNumber: string) {
    this.spreadValueToChildExpenses(
      'PO Number',
      confirm => this.currentState.spreadPONumberToChildren = confirm
    );
  }

  spreadValueToChildExpenses(fieldName: string, callback: (val: boolean) => void) {
    const hasChildren = this.currentState.expenses.length > 0;
    if (hasChildren) {
      this.programDetailsService
        .confirmSpreadValueToChildren(fieldName)
        .pipe(takeUntil(this.destroy$))
        .subscribe(confirm => callback(confirm));
    }
  }

  private handleSegmentOnLocationChange(location: string, onCancel: () => void) {
    const formData = this.programFormData.value as ProgramDetailsForm;
    const onReplace = (parentSegmentData) => {
      const segmentSelectItem = this.budgetObjectDetailsManager.segmentedValueToSelectItem(parentSegmentData, this.segmentSelectItems);
      this.programFormData.patchValue({ segment: segmentSelectItem });
      // If we apply new parent's segment -> then we should 'spreadSegmentToChildren' implicitly
      this.currentState.spreadSegmentToChildren = true;
      this.handleSegmentChanged(segmentSelectItem);
    };

    this.budgetObjectDetailsManager.syncSegmentsOnLocationUpdate({
      objectType: this.objectType,
      campaigns: this.campaigns,
      segment: formData.segment,
      location,
      onCancel,
      onReplace
    });
  }

  handleTasksUpdate($event: TaskListChangeEvent) {
    this.currentState.tasks = $event.data;
    if ($event.saveState) {
      this.prevState.tasks = createDeepCopy(this.currentState.tasks);
    }
    this.syncUnsavedChangesFlag();
  }

  updateDetailsExpensesData() {
    this.detailsExpensesData = this.budgetObjectActionsShared.updateDetailsExpensesData(
      [],
      [],
      this.currentState.expenses,
      this.currentState.objectId,
      this.objectType
    );
  }

  private updateExpenses() {
    this.programDetailsService.updateExpenses(this.companyId, this.currentState).subscribe(
      () => {
        this.updateDetailsExpensesData();
        this.updateDetailsExpensesTotals();
      }
    );
  }

  private applyExternalIntegrationRestrictions() {
    const fieldsToDisable = ['name', 'typeId', 'startDate'];
    const { filteredObjectTypes, integrationTypeSelected } = BudgetObjectService.processIntegrationObjectTypes(
      this.programTypes,
      this.currentState.typeId
    );

    this.hasExternalIntegrationType = integrationTypeSelected;
    this.programTypes = filteredObjectTypes;
    if (this.hasExternalIntegrationType) {
      fieldsToDisable.forEach(fieldName => this.programFormData.get(fieldName)?.disable({ emitEvent: false }));
    }
  }

  public handleOwnerChange(change: MatSelectChange) {
    const ownerValue = change.value;
    this.defineAllowedSegments(ownerValue);
  }

  private triggerBudgetObjectEvent(programId: number, eventType: BudgetObjectEventType, prevState?: ProgramDetailsState): void {
    const context: BudgetObjectEventContext = {
      objectId: programId,
      segmentId: this.currentState.segment?.segmentId,
      sharedCostRuleId: this.currentState.segment?.sharedCostRuleId,
      parentObject: this.currentState.parentObject,
      objectTypeId: this.currentState.typeId,
      objectName: this.currentState.name,
      objectMode: this.currentState.mode
    };

    if (prevState) {
      context.prevSegmentId = prevState.segment?.segmentId;
      context.prevSharedCostRuleId = prevState.segment?.sharedCostRuleId;
      context.prevParentObject = prevState.parentObject;
    }

    this.budgetObjectDetailsManager.triggerBudgetObjectEvent({
      targetObject: BudgetObject.Program,
      eventType,
      context
    });
  }
}
