import { CampaignDetailsState } from '../../types/budget-object-details-state.interface';
import { DetailsObjectStateMapper } from '../../types/budget-object-state-mapper.interface';
import { Injectable } from '@angular/core';
import { CampaignAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { Configuration } from 'app/app.constants';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { CampaignDO } from 'app/shared/types/campaign.interface';
import { CEGStatus } from '@shared/enums/ceg-status.enum';

@Injectable()
export class CampaignStateMapper implements DetailsObjectStateMapper {
  constructor(private configuration: Configuration) {}

  stateToDataObject(state: Partial<CampaignDetailsState>, isCurrentBudgetWithNewCEGStructure?: boolean): Partial<CampaignDO> {
    const dataObject: Partial<CampaignDO> = {};

    if (state.name) {
      dataObject.name = state.name;
    }
    if (state.hasOwnProperty('lockForIntegrations')) {
      dataObject.lock_for_integrations = state.lockForIntegrations;
    }
    if (state.hasOwnProperty('targetAudience')) {
      dataObject.target_audience = state.targetAudience;
    }
    if (state.hasOwnProperty('messaging')) {
      dataObject.key_messages = state.messaging;
    }
    if (state.hasOwnProperty('notes')) {
      dataObject.note = state.notes;
    }
    if (state.budgetId) {
      dataObject.budget = state.budgetId;
    }
    if (state.companyId) {
      dataObject.company = state.companyId;
    }
    if (state.segment && state.segment.segmentId) {
      dataObject.company_budget_segment1 = state.segment.segmentId;
      dataObject.split_rule = null;
      if (state.spreadSegmentToChildren) {
        dataObject.process_nested = state.spreadSegmentToChildren;
      }
    }
    if (state.segment && state.segment.sharedCostRuleId) {
      dataObject.split_rule = state.segment.sharedCostRuleId;
      dataObject.company_budget_segment1 = null;
      if (state.spreadSegmentToChildren) {
        dataObject.process_nested = state.spreadSegmentToChildren;
      }
    }

    if (state.segment && !state.segment.segmentId && !state.segment.sharedCostRuleId) {
      // transition to Segmentless Campaign
      dataObject.split_rule = null;
      dataObject.company_budget_segment1 = null;
    }

    if (state.hasOwnProperty('parentObject')) {
      dataObject.goal =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.goal ?
          state.parentObject.id :
          null;

      dataObject.parent_campaign =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.campaign ?
          state.parentObject.id :
          null;
    }

    if (state.createdBy) {
      dataObject.created_by = state.createdBy;
    }
    if (state.ownerId) {
      dataObject.owner = state.ownerId;
    }
    if (state.typeId) {
      dataObject[isCurrentBudgetWithNewCEGStructure ? 'object_type' : 'campaign_type'] = state.typeId;
    }
    if (state.startDate) {
      dataObject.start_date = state.startDate;
    } else if (state.startDate === '') {
      dataObject.start_date = null;
    }
    if (state.endDate) {
      dataObject.end_date = state.endDate;
    } else if (state.endDate === '') {
      dataObject.end_date = null;
    }
    if (state.hasOwnProperty('amount')) {
      dataObject.amount = state.amount;
    }
    if (state.hasOwnProperty('glCode')) {
      dataObject.gl_code = state.glCode;
    }
    if (state.hasOwnProperty('vendor')) {
      dataObject.vendor = state.vendor;
    }
    if (state.hasOwnProperty('amountStatus')) {
      dataObject.amount_status = state.amountStatus;
    }
    if (state.hasOwnProperty('currencyCode')) {
      dataObject.source_currency = state.currencyCode;
    }
    if (state.hasOwnProperty('keyMetricId')) {
      dataObject.key_metric = state.keyMetricId as number;
    }
    return dataObject;
  }

  dataObjectToState(dataObject: Partial<CampaignDO>): Partial<CampaignDetailsState> {
    return {
      objectId: dataObject.id || null,
      goalId: dataObject.goal || null,
      parentCampaignId: dataObject.parent_campaign || null,
      name: dataObject.name || null,
      amount: dataObject.amount || 0,
      forecastAmount: dataObject.forecast_amount || 0,
      allocations: dataObject.campaign_allocations as CampaignAllocation[] || [],
      budgetId: dataObject.budget || null,
      companyId: dataObject.company || null,
      createdBy: dataObject.created_by || null,
      startDate: dataObject.start_date || '',
      endDate: dataObject.end_date || '',
      messaging: dataObject.key_messages || null,
      notes: dataObject.note || '',
      ownerId: dataObject.owner || null,
      mode: dataObject.mode || ObjectMode.Open,
      segment: {
        segmentId: dataObject.company_budget_segment1 || null,
        sharedCostRuleId: dataObject.split_rule || null
      },
      targetAudience: dataObject.target_audience || null,
      typeId: dataObject.campaign_type || dataObject.object_type || null,
      statusTotals: { ...dataObject.status_totals },
      metricData: dataObject.metric_data || null,
      attachmentMappings: dataObject.attachments || [],
      keyMetricId: dataObject.key_metric || null,
      externalId: dataObject.external_id || '',
      created: dataObject.crd || '',
      updated: dataObject.upd || '',
      glCode: dataObject.gl_code || null,
      vendor: dataObject.vendor || null,
      sourceAmount: dataObject.campaign_allocations?.reduce((sum, alloc) => sum + alloc.source_amount, 0) || 0,
      sourceForecastAmount: dataObject.campaign_allocations?.reduce((sum, alloc) => sum + alloc.source_forecast_amount, 0) || 0,
      currencyCode: dataObject.source_currency,
      amountStatus: dataObject.amount_status as CEGStatus,
      source: dataObject.source || '',
      campaign_integrated_source : dataObject.campaign_integrated_source || false,
    };
  }
}
