import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { BulkUpdateDialogComponent } from '../components/bulk-update-dialog/bulk-update-dialog.component';
import { BudgetBaselineListContext, BulkUpdateDialogContext, DialogContext } from '../types/dialog-context.interface';
import { BudgetBaselineModalComponent } from '../../budget-settings/components/budget-baseline-modal/budget-baseline-modal.component';
import { HierarchySelectModalComponent } from 'app/manage-table/components/hierarchy-select-modal/hierarchy-select-modal.component';
import { HierarchyDialogContext } from 'app/manage-table/components/hierarchy-select-modal/hierarchy-select-modal.types';
import { MoveToBudgetDialogComponent } from '@spending/components/move-to-budget-dialog/move-to-budget-dialog.component';
import { BudgetDataDuplicateSpecificationDialogComponent } from '../../budget-settings/components/budget-data-duplicate-specification-dialog/budget-data-duplicate-specification-dialog.component';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class BudgetObjectDialogService {
  private readonly DEFAULT_DIALOG_TITLE = 'Planful says';
  private readonly UNSAVED_CHANGES_DIALOG_TITLE = 'You have unsaved changes';
  private readonly BASELINE_DIALOG_TITLE = 'Name Baseline';
  private readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    width: '380px',
    autoFocus: false,
    restoreFocus: false
  };
  public unsavedChangesResponse$: Subject<boolean> = new Subject<boolean>();
  public deleteEntityResponse$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly dialog: MatDialog) {}

  public openDialog<T>(dialogComponent: ComponentType<T>, config: MatDialogConfig): void {
    this.dialog.open(dialogComponent, config);
  }

  openUnsavedChangesDialog(saveHandler: Function, cancelHandler?: Function) {
    const dialogData: DialogContext = {
      title: this.UNSAVED_CHANGES_DIALOG_TITLE,
      content: 'Do you want to save these changes before you go?',
      cancelAction: {
        label: 'Discard',
        handler: () => cancelHandler && cancelHandler()
      },
      submitAction: {
        label: 'Save & Go',
        handler: () => saveHandler()
      }
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(() => this.unsavedChangesResponse$.next(true));

    return this.unsavedChangesResponse$;
  }

  openDeleteEntityDialog(deleteHandler: Function, objectType: string = 'object', customContent = { title: '', message: '' }) {
    const getDefaultMessage = (): string => {
      return objectType === 'file'
        ? `Are you sure you want to delete this ${objectType}?`
        : `Are you sure you want to delete this ${objectType}?<br>You cannot undo this action.`;
    }

    const dialogData: DialogContext = {
      title: customContent?.title || this.DEFAULT_DIALOG_TITLE,
      content: customContent?.message || getDefaultMessage(),
      cancelAction: {
        label: 'Cancel',
        handler: () => null
      },
      submitAction: {
        label: 'Delete',
        handler: () => deleteHandler()
      }
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '380px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(() => this.deleteEntityResponse$.next(true));

    return this.deleteEntityResponse$;
  }

  openAsyncConfirmationDialog<T>(confirmValue: T, declineValue: T, content: string, title: string, submitLabel?: string ): Observable<T> {
    const confirmationResult$ = new Subject<T>();
    this.openConfirmationDialog({
      title,
      content,
      submitAction: {
        label: submitLabel,
        handler: () => {
          confirmationResult$.next(confirmValue);
          confirmationResult$.complete();
        }
      },
      cancelAction: {
        handler: () => {
          confirmationResult$.next(declineValue);
          confirmationResult$.complete();
        }
      }
    }, { width: '480px' })
      .afterClosed()
      .subscribe(
      confirmed => {
        // We should emit decline value if we closed dialog implicitly
        if (!confirmed) {
          confirmationResult$.next(declineValue);
          confirmationResult$.complete();
        }
      }
    );

    return confirmationResult$;
  }

  openConfirmationDialog(context: Partial<DialogContext>, dialogConfig: MatDialogConfig = {}) {
    const dialogData: DialogContext = {
      title: this.DEFAULT_DIALOG_TITLE,
      ...context
    };
  
    if (dialogData.submitAction && !dialogData.submitAction.label) {
      dialogData.submitAction.label = 'Ok';
    }
    if (dialogData.cancelAction && !dialogData.cancelAction.label) {
      dialogData.cancelAction.label = 'Cancel';
    }
  
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.DEFAULT_DIALOG_CONFIG,
      ...dialogConfig,
      data: dialogData,
      disableClose: false // Ensure dialog can be closed by clicking outside
    });
  
    // Handle clicking outside
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined || result === null) {
        // If the dialog is closed without an explicit action, execute cancelAction if it exists
        if (dialogData.cancelAction?.handler) {
          dialogData.cancelAction.handler();
        }
      }
    });
  
    return dialogRef;
  }
  

  openBulkUpdateDialog(context: BulkUpdateDialogContext) {
    const dialogData: BulkUpdateDialogContext = { ...context };
    if (dialogData.submitAction && !dialogData.submitAction.label) {
      dialogData.submitAction.label = 'Save';
    }
    if (dialogData.cancelAction && !dialogData.cancelAction.label) {
      dialogData.cancelAction.label = 'Cancel';
    }

    return this.dialog.open(BulkUpdateDialogComponent, {
      width: '380px',
      data: dialogData
    });
  }

  openBudgetBaselineDialog(
    context: Partial<BudgetBaselineListContext>,
    dialogConfig: MatDialogConfig = {}
  ): MatDialogRef<BudgetBaselineModalComponent> {
    const dialogData: BudgetBaselineListContext = {
      title: this.BASELINE_DIALOG_TITLE,
      baselineList: context.baselineList
    };

    return this.dialog.open(BudgetBaselineModalComponent, {
      ...this.DEFAULT_DIALOG_CONFIG,
      ...dialogConfig,
      data: dialogData
    });
  }

  openMoveToDialog(context: HierarchyDialogContext): MatDialogRef<HierarchySelectModalComponent> {
    return this.dialog.open(HierarchySelectModalComponent, {
      width: '480px',
      panelClass: ['reset-paddings', 'hierarchy-select-modal'],
      data: context
    });
  }

  openMoveToBudgetDialog(context): MatDialogRef<MoveToBudgetDialogComponent> {
    return this.dialog.open(MoveToBudgetDialogComponent, {
      width: '480px',
      panelClass: ['reset-paddings'],
      data: context
    });
  }

  openDuplicateBudgetSpecificationDialog(context: Partial<DialogContext>, dialogConfig: MatDialogConfig = {}) {
    return this.dialog.open(BudgetDataDuplicateSpecificationDialogComponent, {
      ...this.DEFAULT_DIALOG_CONFIG,
      ...dialogConfig,
      data: context
    });
  }
}
